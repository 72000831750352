import { put } from 'redux-saga/effects'
import { UsersApi } from "../../services/api/usersApi.js";
import { userTypes } from "../action-types/users-types.js";

export function* getUserByIdSaga(action) {
    try {
        yield put({ type: userTypes.GET_USER_BY_ID_REQUEST });
        const response = yield UsersApi.getUserById(action.payload);

        if (response.fields) {
            yield put({ type: userTypes.GET_USER_BY_ID_SUCCESS, payload: response.fields });
        } else {
            yield put({ type: userTypes.GET_USER_BY_ID_ERROR, payload: "ERROR" });
        }
    } catch (e) {
        yield put({ type: userTypes.GET_USER_BY_ID_ERROR, payload: e.message });
    }
}