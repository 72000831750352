export class AnswersHelper {
    static getRecentAnswers(arr = []) {
        const sortedArr = arr.sort((a, b) => (
            new Date(b.createdTime).valueOf() - new Date(a.createdTime).valueOf()
        ));

        const firstFiveRecords = [ ...sortedArr ].splice(0, 5)
            .map(rec => rec.fields.videoAnswer);

        return {
            sortedArr,
            firstFiveRecords
        };
    }
}
