const VideoModal = ({
                        srcVideo,
                        srcThumbnail,
                        setOpened,
                    }) => {

    return (
        <>
            <video
                className="search-screen_videos"
                playsInline
                loop
                style={ {
                    backgroundImage: `url(${ srcThumbnail })`,
                } }
                onClick={ () => setOpened(true) }
                muted
            >
                <source src={ srcVideo }/>
            </video>
        </>
    )
}

export default VideoModal;
