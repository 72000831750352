import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import Feed from "../Feeds/Feed";
import { clearPlayerAnswers } from "../../store/action-creators/answers-actions";

const AnswersPlayer = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const {
        playerAnswers,
        playerSelectedAnswerId,
        fromComponent
    } = useSelector(state => state.answersReducer);

    useEffect(() => {
        if (playerAnswers == null) {
            navigate('/');
        }
    }, [ navigate, playerAnswers ]);

    useEffect(() => {
        return () => {
            dispatch(clearPlayerAnswers());
        }
    }, [ dispatch ]);

    if (playerAnswers == null) {
        return null;
    }
   
    return (
        <>
            { playerAnswers.map((answer) => (
                <Feed
                    key={ answer.id }
                    feed={ answer.fields }
                    scrollTo={ answer.id === playerSelectedAnswerId }
                    component={ fromComponent }
                />
            )) }
        </>
    );
};

export default AnswersPlayer;
