import React, { useState } from 'react';
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import userAvatar from "../../assets/user.png";
import someImage from "../../assets/oboi-epl-5120x2880-5k-4k-8k-les-gori-zakat-zvezdi-3956.jpg";
import { EmergencyRecordingRounded } from "@mui/icons-material";

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100vh - 150px)',
        paddingLeft: '19px',
        paddingRight: '19px'
    },
    titlePage: {
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        paddingTop: "35px"
    },
    titleWrapper: {
        display: "flex",
        justifyContent: "flex-start"
    },
    titleText: {
        fontWeight: '530 !important',
        fontSize: '17px !important',
        lineHeight: '22.5px !important',
        color: '#212435 !important',
        fontStyle: "normal !important"
    },
    wrapperBlock: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between"
    },
    blockProfile: {
        display: "flex",
        flexDirection: "row",
        paddingTop: "10px",
        alignItems: "center",
    },
    followButton: {
        backgroundColor: "#7165E3 !important",
        color: "#FFFFFF !important",
        borderRadius: "15px !important",
        fontSize: "13px !important",
        lineHeight: "24px !important",
        width: "136px !important",
        height: "40px !important",
    },
    unFollowButton: {
        backgroundColor: "#FFFFFF !important",
        color: "#7165E3 !important",
        borderRadius: "15px !important",
        border: "1px solid #7165E3 !important",
        fontSize: "13px !important",
        lineHeight: "24px !important",
        width: "136px !important",
        height: "40px !important",
    },
    avatar: {
        width: "56px",
        height: "56px"
    },
    image: {
        width: '56px',
        height: '56px',
        borderRadius: "10px"
    },
    followButtonBlock: {}
}));


const Activity = () => {
    const classes = useStyles();
    const [ isFollowed, setIsFollowed ] = useState(true);
    const handleClick = () => {
        setIsFollowed(!isFollowed);
    }

    return (
        <Box className={ classes.root }>
            <Box className={ classes.titlePage }>
                <Typography variant='h5' style={ {fontSize: '16px'} }>
                    Activity
                </Typography>
            </Box>
            <Box style={ {paddingTop: '35px'} }>
                <Typography variant='h6' className={ classes.titleText }>
                    Yesterday
                </Typography>
                <Box className={ classes.wrapperBlock }>
                    <Box className={ classes.blockProfile }>
                        <Avatar className={ classes.avatar }/>
                        <Typography
                            style={ {paddingLeft: "10px", fontSize: "15px",} }>
                            John Green<br/>
                            <small style={ {
                                fontSize: "13px",
                                color: "#9092A0",
                            } }> follows you</small>
                        </Typography>
                    </Box>
                    <Box style={ {display: "flex", alignItems: "center"} }>
                        <Button
                            onClick={ handleClick }
                            className={
                                isFollowed ? classes.followButton : classes.unFollowButton }
                        >
                            { isFollowed ? "Follow" : "Unfollow" }
                        </Button>
                    </Box>
                </Box>
            </Box>

            <Box style={ {paddingTop: '42px'} }>
                <Typography variant='h6' className={ classes.titleText }>
                    This week
                </Typography>
                <Box className={ classes.wrapperBlock }>
                    <Box className={ classes.blockProfile }>
                        <Avatar className={ classes.avatar }/>
                        <Typography
                            style={ {
                                paddingLeft: "10px",
                                fontSize: "15px",
                                maxWidth: "139px",
                                lineHeight: '18px',
                            } }
                        >
                            Susan Connor<br/>
                            <small style={ {
                                fontSize: "13px",
                                color: "#9092A0",
                                lineHeight: '18px',
                            } }>
                                asked you a question: ‘Where and when were you born?’
                            </small>
                        </Typography>
                    </Box>
                    <Box style={ {display: "flex", alignItems: "center"} }>
                        <Button className={ classes.followButton }>
                            Follow
                        </Button>
                    </Box>
                </Box>
            </Box>

            <Box style={ {paddingTop: '10px'} }>
                <Box className={ classes.wrapperBlock }>
                    <Box className={ classes.blockProfile }>
                        <Avatar className={ classes.avatar } src={ userAvatar }/>
                        <Typography
                            style={ {
                                paddingLeft: "10px",
                                fontSize: "15px",
                                lineHeight: '18px',
                            } }
                        >
                            Susan Connor and 325 others<br/>
                            <small style={ {
                                fontSize: "13px",
                                color: "#9092A0",
                                lineHeight: '18px',
                            } }>
                                liked your answer | 3 days ago
                            </small>
                        </Typography>
                    </Box>
                    <Box style={ {display: "flex", alignItems: "center"} }>
                        <img className={ classes.image } alt="someImage" src={ someImage }/>
                    </Box>
                </Box>
            </Box>
            <Box style={ {paddingTop: '10px'} }>
                <Box className={ classes.wrapperBlock }>
                    <Box className={ classes.blockProfile }>
                        <Avatar className={ classes.avatar }/>
                        <Typography
                            style={ {
                                paddingLeft: "10px",
                                fontSize: "15px",
                                lineHeight: '18px',

                            } }
                        >
                            Anthony Fuller and 325 others<br/>
                            <small style={ {
                                fontSize: "13px",
                                color: "#9092A0",
                                lineHeight: '18px',
                            } }>
                                shared your answer | 4 days ago
                            </small>
                        </Typography>
                    </Box>
                    <Box style={ {display: "flex", alignItems: "center"} }>
                        <img className={ classes.image } alt="someImage" src={ someImage }/>
                    </Box>
                </Box>
            </Box>
            <Box style={ {paddingTop: '10px'} }>
                <Box className={ classes.wrapperBlock }>
                    <Box className={ classes.blockProfile }>
                        <Avatar className={ classes.avatar } src={ userAvatar }/>
                        <Typography
                            style={ {
                                paddingLeft: "10px",
                                fontSize: "15px",
                                lineHeight: '18px',

                            } }
                        >
                            Stephen K. and 325 others<br/>
                            <small style={ {
                                fontSize: "13px",
                                color: "#9092A0",
                                lineHeight: '18px',
                            } }>
                                commented your video | 4 days ago
                            </small>
                            <b> <br/> Reply</b>
                        </Typography>
                    </Box>
                    <Box style={ {display: "flex", alignItems: "center"} }>
                        <img className={ classes.image } alt="someImage" src={ someImage }/>
                    </Box>
                </Box>
            </Box>
            <Box style={ {paddingTop: '42px'} }>
                <Typography variant='h6' className={ classes.titleText }>
                    This month
                </Typography>
                <Box className={ classes.wrapperBlock }>
                    <Box className={ classes.blockProfile }>
                        <Avatar className={ classes.avatar }/>
                        <Typography
                            style={ {
                                paddingLeft: "10px",
                                fontSize: "15px",
                                maxWidth: "139px",
                                lineHeight: '18px',
                            } }
                        >
                            Nancy Davolio<br/>
                            <small style={ {
                                fontSize: "13px",
                                color: "#9092A0",
                                lineHeight: '18px',
                            } }>
                                asked you a question: ‘Where and when were you born?
                            </small>
                        </Typography>
                    </Box>
                    <Box style={ {display: "flex", alignItems: "center"} }>
                        <Button className={ classes.followButton }>
                            Record <EmergencyRecordingRounded style={ {marginLeft: '5px'} }/> </Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
};

export default Activity;
