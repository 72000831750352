import { recordsTypes } from "../action-types/records-types";

export const getRecords = () => ({ type: recordsTypes.GET_RECORDS });

export const getRecordsByUserId = (data) => ({ type: recordsTypes.GET_RECORDS_BY_UID, payload: data })





