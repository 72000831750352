import Header from "../Header";
import MainRotes from "./MainRotes";
import AuthRouting from "./AuthRouting";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import LoadingMask from "../Loading/LoadingMask";

const Routing = () => {
    const [ isAuthenticated, setAuthenticated ] = useState(null);
    const {userData, isLoading} = useSelector(state => state.authReducer);

    useEffect(() => {
        const token = localStorage.getItem("user");

        if (token) {
            setAuthenticated(token)
        } else {
            setAuthenticated(null)
        }
    }, [ userData, isAuthenticated ]);


    if (isLoading) return <LoadingMask isLoading={ isLoading }/>;

    return (
        <BrowserRouter>
            <Header/>
            { isAuthenticated ? <MainRotes/> : <AuthRouting/> }
        </BrowserRouter>
    );
};

export default Routing;
