import { loginTypes, logoutTypes, usersRegistrationTypes } from "../../action-types/auth-types";

const initialState = {
    isError: null,
    userData: {},
    isLoading: false
};

export const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case loginTypes.LOGIN_REQUEST:
            return { ...state, isLoading: true }
        case loginTypes.LOGIN_SUCCESS:
            return { ...state, isLoading: false, isError: null, userData: { ...action.payload } }
        case loginTypes.LOGIN_ERROR:
            return { ...state, isLoading: false, isError: action.payload, userData: null }

        case usersRegistrationTypes.REGISTRATION_REQUEST:
            return { ...state, isLoading: true }
        case usersRegistrationTypes.REGISTRATION_SUCCESS:
            return { ...state, isLoading: false, isError: null, userData: { ...action.payload } }
        case usersRegistrationTypes.REGISTRATION_ERROR:
            return { ...state, isLoading: false, isError: action.payload }

        case logoutTypes.LOGOUT_USER:
            return { isLoading: false, isError: null, userData: null }

        default:
            return state
    }
};