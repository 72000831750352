import {useCallback, useEffect, useState} from "react";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';
import HomeIcon from '@mui/icons-material/Home';
import Typography from "@mui/material/Typography";
import SearchIcon from '@mui/icons-material/Search';
import CropSquareIcon from '@mui/icons-material/CropSquare';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import "./Footer.css";

const Footer = () => {
    const [ activeTab, setActiveTab ] = useState(1);

    const toggleTab = useCallback((tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab)
        }
    }, [ activeTab ]);

    useEffect(() => {
        const rout = window.location.href.split('/')[3];

        switch (rout) {
            case 'search': {
                setActiveTab(2);
                break;
            }

            case 'questions': {
                setActiveTab(3);
                break;
            }

            case 'activity': {
                setActiveTab(4);
                break;
            }

            case 'profile': {
                setActiveTab(5);
                break;
            }
        }
    }, [])

    return (
        <Box className="footer__wrapper">
            <Box onClick={ () => toggleTab(1) }
                 className={ `footer_icon_box ${ activeTab === 1 ? 'activeTab-footer' : '' } ` }>
                <Link to="/">
                    <HomeIcon className="action__icon-footer"/>
                </Link>
                <Typography className="footer_desc">Home</Typography>
            </Box>
            <Box onClick={ () => toggleTab(2) }
                 className={ `footer_icon_box ${ activeTab === 2 ? 'activeTab-footer' : '' } ` }>
                <Link to="/search">
                    <SearchIcon className="action__icon-footer"/>
                </Link>
                <Typography className="footer_desc">Search</Typography>
            </Box>
            <Box onClick={ () => toggleTab(3) }
                 className={ `footer_icon_box center-icon ${ activeTab === 3 ? 'activeTab-footer' : '' } ` }>
                <Link to="/questions">
                    <AddIcon className="action__icon-footer"/>
                </Link>
            </Box>
            <Box onClick={ () => toggleTab(4) }
                 className={ `footer_icon_box ${ activeTab === 4 ? 'activeTab-footer' : '' } ` }>
                <Link to="/activity">
                    <CropSquareIcon className="action__icon-footer"/>
                </Link>
                <Typography className="footer_desc">Activity</Typography>
            </Box>
            <Box onClick={ () => toggleTab(5) }
                 className={ `footer_icon_box ${ activeTab === 5 ? 'activeTab-footer' : '' } ` }>
                <Link to="/profile">
                    <PermIdentityIcon className="action__icon-footer"/>
                </Link>
                <Typography className="footer_desc">Profile</Typography>
            </Box>
        </Box>
    );
};

export default Footer;
