export const recordsTypes = {
    GET_RECORDS: 'GET_RECORDS',
    GET_RECORDS_ERROR: 'GET_RECORDS_ERROR',
    GET_RECORDS_REQUEST: 'GET_RECORDS_REQUEST',
    GET_RECORDS_SUCCESS: 'GET_RECORDS_SUCCESS',

    GET_RECORDS_BY_UID: 'GET_RECORDS_BY_UID',
    GET_RECORDS_BY_UID_ERROR: 'GET_RECORDS_BY_UID_ERROR',
    GET_RECORDS_BY_UID_SUCCESS: 'GET_RECORDS_BY_UID_SUCCESS',
    GET_RECORDS_BY_UID_REQUEST: 'GET_RECORDS_BY_UID_REQUEST',
}