export const questionsTypes = {
    GET_QUESTIONS: 'GET_QUESTIONS',
    GET_QUESTIONS_ERROR: 'GET_QUESTIONS_ERROR',
    GET_QUESTIONS_REQUEST: 'GET_QUESTIONS_REQUEST',
    GET_QUESTIONS_SUCCESS: 'GET_QUESTIONS_SUCCESS',

    GET_QUESTIONS_BY_UID: 'GET_QUESTIONS_BY_UID',
    GET_QUESTIONS_BY_UID_ERROR: 'GET_QUESTIONS_BY_UID_ERROR',
    GET_QUESTIONS_BY_UID_SUCCESS: 'GET_QUESTIONS_BY_UID_SUCCESS',
    GET_QUESTIONS_BY_UID_REQUEST: 'GET_QUESTIONS_BY_UID_REQUEST',

    GET_QUESTIONS_BY_ID: 'GET_QUESTIONS_BY_ID',
    GET_QUESTIONS_BY_ID_ERROR: 'GET_QUESTIONS_BY_ID_ERROR',
    GET_QUESTIONS_BY_ID_SUCCESS: 'GET_QUESTIONS_BY_ID_SUCCESS',
    GET_QUESTIONS_BY_ID_REQUEST: 'GET_QUESTIONS_BY_ID_REQUEST',

    CREATE_QUESTION: 'CREATE_QUESTION',
    CREATE_QUESTION_ERROR: 'CREATE_QUESTION_ERROR',
    CREATE_QUESTION_REQUEST: 'CREATE_QUESTION_REQUEST',
    CREATE_QUESTION_SUCCESS: 'CREATE_QUESTION_SUCCESS',

    CLEAR_QUESTIONS_STORE: 'CLEAR_QUESTIONS_STORE',
    CLEAR_QUESTIONS_STORE_ERROR: 'CLEAR_QUESTIONS_STORE_ERROR',
    CLEAR_QUESTIONS_STORE_REQUEST: 'CLEAR_QUESTIONS_STORE_REQUEST',
    CLEAR_QUESTIONS_STORE_SUCCESS: 'CLEAR_QUESTIONS_STORE_SUCCESS'
}