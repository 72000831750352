export const filterByLockedVideo = (answers) => {
    const {userId} = JSON.parse(localStorage.getItem("user"));
    return answers.filter(answer => {
        const ownerProfile = answer.fields.responderName[0] === userId;
        if (ownerProfile) {
            return answer
        } else {
            return !answer.fields.hasOwnProperty('isLockedVideo')
        }
    });
};

export const sortByCreatedTime = ((answers) => {
    return answers.sort((a, b) => {
        const dateA = new Date(a.createdTime).getTime();
        const dateB = new Date(b.createdTime).getTime();

        return dateA < dateB ? 1 : -1;
    });
});
