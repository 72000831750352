import { recordsTypes } from "../../action-types/records-types";

const initialState = {
    records: [],
    isError: null,
    isLoading: false
};

export const recordsReducer = (state = initialState, action) => {
    switch (action.type) {
        case recordsTypes.GET_RECORDS_REQUEST:
            return { ...state, isLoading: true }
        case recordsTypes.GET_RECORDS_SUCCESS:
            return { ...state, isLoading: false, isError: null, records: [ ...action.payload ] }
        case recordsTypes.GET_RECORDS_ERROR:
            return { ...state, isLoading: false, isError: action.payload }

        case recordsTypes.GET_RECORDS_BY_UID_REQUEST:
            return { ...state, isLoading: true }
        case recordsTypes.GET_RECORDS_BY_UID_SUCCESS:
            return { ...state, isLoading: false, isError: null, records: [ ...action.payload ] }
        case recordsTypes.GET_RECORDS_BY_UID_ERROR:
            return { ...state, isLoading: false, isError: action.payload }
        default:
            return state
    }
};