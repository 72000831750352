import axios from "axios";
import { BASE_URL } from "./urls";

export class RecordsApi {
    static async getRecords() {
        const data = await axios.get(`${BASE_URL}${process.env.REACT_APP_BASE_ID}/answers`, {
            headers: {
                'Authorization': `Bearer ${process.env.REACT_APP_API_KEY}`
            }
        })
        return data;
    };

    static async getRecordsByUserId(id) {
        return axios.get(`${BASE_URL}${process.env.REACT_APP_BASE_ID}/answers`, {
            headers: {
                'Authorization': `Bearer ${process.env.REACT_APP_API_KEY}`
            },
            params: {
                filterByFormula: `{userId} = "${id}"`
            }
        })
    }
}