import Grid from "@mui/material/Grid";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import { experimentalStyled as styled } from '@mui/material/styles';

const Item = styled(Box)(({ theme }) => ({
    padding: theme.spacing(1),
    width: '90%',
    margin: '0 auto',
    height: '5rem',
    fontSize: '1rem',
    borderRadius: '10px',
    boxShadow: "0px 4px 15px rgba(49, 43, 103, 0.15)",
    color: theme.palette.text.secondary,
}));

const MyQuestions = ({ question }) => {
    const { question: questionText, answers = [] } = question.fields;

    return (
        <Grid item xs={4} sm={4} md={4}>
            <Item>
                <Typography style={{ "fontSize": "1rem" }} variant='h6'>
                    {questionText}
                </Typography>
                <Box style={{ "paddingTop": "20px", "display": "flex", "alignItems": "center" }}>
                    <Typography>
                        Answers: {answers.length}
                    </Typography>
                </Box>
            </Item>
        </Grid>
    )
};

export default MyQuestions;
