import { Skeleton } from "@mui/material";
import './SkeletonWrapper.css';

const SkeletonWrapper = ({ fromComponent }) => {
    return (
        <div className={`skeleton-wrapper_${fromComponent}`}>
            <Skeleton variant="rectangular" height="8rem" className="skeleton"/>
        </div>
    )
}

export default SkeletonWrapper;
