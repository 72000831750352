import * as React from 'react';
import Box from '@mui/material/Box';
import { Link, useLocation } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router'

const Header = () => {
    let history = useNavigate();
    const {pathname} = useLocation();
    const token = localStorage.getItem("user");
    const tagName = pathname.split('/')[2];

    const renderHeader = () => {
        return (
            <Box sx={ {flexGrow: 1} }>
                <AppBar style={ {backgroundColor: '#7165E3'} }>
                    <Toolbar>
                        <Typography component="div">
                            {
                                <ArrowBackIcon onClick={ () => history(-1) }/>
                            }

                        </Typography>
                        <Typography component="div" style={ {marginLeft: '10px'} }>
                            { pathname.startsWith('/user') ? null : tagName }
                        </Typography>
                        {
                            !token
                                ?
                                <>
                                    <div>
                                        <Button variant="contained" color="inherit">
                                            <Link style={ {color: "black", textDecoration: "none"} } to="/signup">Sign
                                                up</Link>
                                        </Button>
                                    </div>
                                    <div>
                                        <Button color="inherit">
                                            <Link style={ {color: "white", textDecoration: "none"} } to="/login">Sign
                                                in</Link>
                                        </Button>
                                    </div>
                                </>
                                :
                                null
                        }
                    </Toolbar>
                </AppBar>
            </Box>
        );
    }
    const isSearchTagPath = pathname === `/search/${ tagName }`;
    const isAnswerPlayer = pathname === `/answers-player/${ tagName }`;
    const isUserPage = pathname.startsWith('/user');
    const isRenderHeader = isSearchTagPath || isAnswerPlayer || isUserPage;

    return isRenderHeader ? renderHeader() : null;
}

export default Header;