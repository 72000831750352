import Grid from "@mui/material/Grid";
import { Paper } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { experimentalStyled as styled } from '@mui/material/styles';
import SkeletonWrapper from "../../components/SkeletonWrapper/SkeletonWrapper";

const Item = styled(Paper)(({theme}) => ({
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const MyAnswers = ({fields, setOpened}) => {
    const {videoAnswer, thumbnail, isLockedVideo} = fields;

    return (
        <Grid item xs={ 2 } sm={ 4 } md={ 4 }>
            {
                !videoAnswer
                    ?
                    <SkeletonWrapper fromComponent='profile'/>
                    :
                    <Item style={ {
                        'padding': '0',
                        'boxShadow': 'none',
                        'borderRadius': '10px !important',
                        position: "relative",

                    } }>
                        { isLockedVideo && <LockOutlinedIcon style={ {
                            color: "#ab3b3b",
                            position: "absolute",
                            zIndex: 1,
                            right: 2,
                            fontSize: '20px',
                            top: 5
                        } }/> }
                        <video
                            className="video__player profile-answer_wrapper profile-answer"
                            loop
                            playsInline
                            style={ {
                                borderRadius: '10px',
                                backgroundSize: 'cover',
                                backgroundImage: `url(${ thumbnail })`,
                            } }
                            onClick={ () => setOpened(true) }
                        >
                            <source type="video/mp4" src={ videoAnswer }/>
                        </video>
                    </Item>
            }
        </Grid>
    )
};

export default MyAnswers;
