import axios from "axios";
import { BASE_URL } from "./urls";

export class TagApi {
    static async createTag(tags, answerId) {

        const requestsArr = tags.map((tag) => {
            const fields = {
                tagName: tag,
                answerId: [ answerId ]
            }
            return axios.post(`${ BASE_URL }${ process.env.REACT_APP_BASE_ID }/tags`, {fields}, {
                headers: {
                    'Authorization': `Bearer ${ process.env.REACT_APP_API_KEY }`
                }
            })
        });

        return axios.all(requestsArr);
    }

    static async getAllTags() {
        return axios.get(`${ BASE_URL }${ process.env.REACT_APP_BASE_ID }/tags`, {
            headers: {
                'Authorization': `Bearer ${ process.env.REACT_APP_API_KEY }`
            }
        })
    }
}
