import { Navigate, Route, Routes } from "react-router";

import Footer from "../Footer/Footer";
import Home from "../../screens/Home/Home";
import Profile from "../../screens/Profile/Profile";
import Activity from "../../screens/Activity/Activity";
import UserPage from "../../screens/Users/UserPage.js";
import AnswersPlayer from "../AnswersPlayer/AnswersPlayer";
import SearchScreen from "../../screens/Search/SearchScreen";
import Questions from "../../screens/Questions/QuestionsList";
import SearchAnswers from "../../screens/Search/SearchAnswers";
import AnswersOnQuestions from '../../screens/Questions/AnswersOnQuestion'

const MainRotes = () => {
    return (
        <>
            <Routes>
                <Route path="/" element={ <Home/> }/>
                <Route path="*" element={ <Navigate to="/"/> }/>

                <Route path="/questions" element={ <Questions/> }/>
                <Route path="*" element={ <Navigate to="/questions"/> }/>

                <Route path="/questions/:id" element={ <AnswersOnQuestions/> }/>
                <Route path="*" element={ <Navigate to="/questions/:id"/> }/>

                <Route path="/user/:id" element={ <UserPage/> }/>
                <Route path="*" element={ <Navigate to="/user/:id"/> }/>

                <Route path="/search" element={ <SearchScreen/> }/>
                <Route path="*" element={ <Navigate to="/search"/> }/>

                <Route path="/search/:tagName" element={ <SearchAnswers/> }/>
                <Route path="*" element={ <Navigate to="/search/:tagName"/> }/>

                <Route path="/profile" element={ <Profile/> }/>
                <Route path="*" element={ <Navigate to="/profile"/> }/>

                <Route path="/answers-player/:tagName" element={ <AnswersPlayer/> }/>
                <Route path="*" element={ <Navigate to="/answers-player/:tagName"/> }/>

                <Route path="/activity" element={ <Activity/> }/>
                <Route path="*" element={ <Navigate to="/activity"/> }/>
            </Routes>
            <Footer/>
        </>
    );
};


export default MainRotes;
