import * as authSagas from './sagas/authSagas';
import { takeEvery } from 'redux-saga/effects';
import * as answerSaga from "./sagas/answersSagas";
import * as recordsSaga from './sagas/recordsSagas';
import * as questionsSaga from './sagas/questionsSagas';
import * as userSaga from './sagas/usersSagas.js';
import * as tagSaga from './sagas/tagsSagas.js';
import { userTypes } from "./action-types/users-types.js";
import { answersTypes } from "./action-types/answers-types";
import { recordsTypes } from "./action-types/records-types";
import { questionsTypes } from "./action-types/questions-types";
import { tagsTypes } from "./action-types/tags-types.js";
import { loginTypes, usersRegistrationTypes } from "./action-types/auth-types";

export function* rootSaga() {
    yield takeEvery(tagsTypes.GET_TAGS, tagSaga.getAllTags);
    yield takeEvery(tagsTypes.CREATE_TAG, tagSaga.createTag);
    yield takeEvery(loginTypes.LOGIN_USER, authSagas.loginSaga);
    yield takeEvery(userTypes.GET_USER_BY_ID, userSaga.getUserByIdSaga);
    yield takeEvery(answersTypes.GET_ANSWERS, answerSaga.getAllAnswers);
    yield takeEvery(recordsTypes.GET_RECORDS, recordsSaga.getRecordsSaga);
    yield takeEvery(answersTypes.CREATE_ANSWERS, answerSaga.createAnswerSaga);
    yield takeEvery(answersTypes.GET_ANSWERS_BY_UID, answerSaga.getAnswersByUid);
    yield takeEvery(answersTypes.UPLOAD_ANSWER_VIDEO, answerSaga.uploadVideoSaga);
    yield takeEvery(questionsTypes.GET_QUESTIONS, questionsSaga.getQuestionsSaga);
    yield takeEvery(answersTypes.CHANGE_VIDEO_STATUS, answerSaga.changeVideoStatus);
    yield takeEvery(recordsTypes.GET_RECORDS_BY_UID, recordsSaga.getRecordsByUidSaga);
    yield takeEvery(questionsTypes.CREATE_QUESTION, questionsSaga.createQuestionSaga);
    yield takeEvery(answersTypes.CLEAR_ANSWERS_STORE, answerSaga.clearAnswersStoreSaga);
    yield takeEvery(usersRegistrationTypes.REGISTRATION_USER, authSagas.registrationSaga);
    yield takeEvery(questionsTypes.GET_QUESTIONS_BY_ID, questionsSaga.getQuestionByIdSaga);
    yield takeEvery(questionsTypes.CLEAR_QUESTIONS_STORE, questionsSaga.clearQuestionsStore);
    yield takeEvery(questionsTypes.GET_QUESTIONS_BY_UID, questionsSaga.getQuestionByUserIdSaga);
    yield takeEvery(answersTypes.GET_ANSWERS_BY_QUESTION_ID, answerSaga.getAnswersByQuestionsId);
}
