import { put } from "redux-saga/effects";
import { TagApi } from "../../services/api/tagApi.js";
import { tagsTypes } from "../action-types/tags-types.js";

export function* createTag(action) {
    try {
        yield put({type: tagsTypes.CREATE_TAG_REQUEST});

        const {tags, answerId} = action.payload;

        const arrResponses = yield TagApi.createTag(tags, answerId);

        const responsesData = [];

        for (const res of arrResponses) {
            responsesData.push(res.data);
        }

        if (arrResponses[0].status === 200) {
            yield put({type: tagsTypes.CREATE_TAG_SUCCESS, payload: responsesData});
        } else {
            yield put({type: tagsTypes.CREATE_TAG_ERROR, payload: arrResponses.error.message});
        }
    } catch (e) {
        yield put({type: tagsTypes.CREATE_TAG_ERROR, payload: e.message});
    }
}

export function* getAllTags() {
    try {
        const response = yield TagApi.getAllTags();

        if (response.status === 200) {
            yield put({type: tagsTypes.GET_TAGS_SUCCESS, payload: response.data.records})
        } else {
            yield put({type: tagsTypes.GET_TAGS_ERROR, payload: response.error.message});
        }
    } catch (e) {
        yield put({type: tagsTypes.GET_TAGS_ERROR, payload: e.message});
    }
}
