import * as React from 'react';
import { useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import "./Profile.css"
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import Logout from "../../components/Authorization/Logout";

export default function BasicMenu() {
    const [ anchorEl, setAnchorEl ] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <SettingsOutlinedIcon
                id="basic-button"
                aria-controls={ open ? 'basic-menu' : undefined }
                aria-haspopup="true"
                aria-expanded={ open ? 'true' : undefined }
                onClick={ handleClick }
            />
            <Menu
                anchorEl={ anchorEl }
                open={ open }
                onClose={ handleClose }
                MenuListProps={ {
                    'aria-labelledby': 'basic-button',
                } }
            >
                <MenuItem disabled>Profile setting</MenuItem>
                <MenuItem>
                    <Logout/>
                </MenuItem>
            </Menu>
        </>
    );
}

