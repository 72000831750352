import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos.js";
import {CardActions, CardContent} from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import Box from "@mui/material/Box";
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {normalizedTagsResponse} from "../../services/helpers/normolizedResponse";
import {getAllTags} from "../../store/action-creators/tags-actions";

import './Tags.css';

const AddTag = ({video, uploadVideoToServer, setOpenedAnswer, setTagPage}) => {
    const [tags, setTags] = useState([]);

    const dispatch = useDispatch();

    const {tags: dbTags} = useSelector((state) => state.tagsReducer);

    const existingTagsList = useMemo(() => {
        return normalizedTagsResponse(dbTags);
    }, [dbTags]);

    const objectURL = useMemo(() => URL.createObjectURL(video), [video]);

    const handleOnChange = useCallback((e, arr) => {
        setTags([...arr]);
    }, []);

    const handleClickUpload = useCallback(() => {
        const tagsArr = tags.map(tag => tag.replace(/[`~!@#$%^&*()_|+\-=?;:'",.]/gi, ''));
        uploadVideoToServer(video, tagsArr);
    }, [tags, video, uploadVideoToServer]);

    const options = existingTagsList.ids.filter((tag) => tag !== tags.find(el => el === tag));

    useEffect(() => {
        dispatch(getAllTags());
    }, [dispatch]);

    return (
        <Box className="tag-page_wrapper">
            <div style={{
                paddingTop: '75px',
                paddingLeft: '20px'
            }}>
                <ArrowBackIosIcon
                    onClick={() => {
                        setOpenedAnswer(true)
                        setTagPage(false)
                    }}
                    style={{
                        color: 'black',
                        cursor: 'pointer'
                    }}
                />

            </div>
            <CardContent className="video_tag-page">
                <video
                    playsInline
                    autoPlay
                    loop
                    muted
                    src={objectURL}
                    style={{maxHeight: '255px'}}
                />
            </CardContent>
            <CardContent className="tags-list">
                <Autocomplete
                    multiple
                    id="tags-outlined"
                    options={options}
                    freeSolo
                    onChange={handleOnChange}
                    renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                            <Chip variant="outlined" label={option} {...getTagProps({index})} />
                        ))
                    }
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Tags"
                        />
                    )}
                />
            </CardContent>
            <CardActions className="tags-actions">
                <Button variant="contained" color="success" onClick={handleClickUpload}>
                    upload
                </Button>
            </CardActions>
        </Box>
    )
}

export default AddTag;
