import { tagsTypes } from "../../action-types/tags-types.js";

const initialState = {
    tags: [],
    isError: null,
    isLoading: false
};

export const tagsReducer = (state = initialState, action) => {
    switch (action.type) {
        case tagsTypes.CREATE_TAG_REQUEST:
            return {...state, isLoading: true}
        case tagsTypes.CREATE_TAG_SUCCESS:
            return {...state, isLoading: false, isError: null, tags: [ ...action.payload ]}
        case tagsTypes.CREATE_TAG_ERROR:
            return {...state, isLoading: false, isError: action.payload, tags: null}

        case tagsTypes.GET_TAGS_REQUEST:
            return {...state, isLoading: true, isError: null}
        case tagsTypes.GET_TAGS_SUCCESS:
            return {...state, isLoading: false, isError: null, tags: [ ...action.payload ]}
        case tagsTypes.GET_TAGS_ERROR:
            return {...state, isLoading: false, isError: action.payload}
        default:
            return state
    }
};
