import { answersTypes } from "../action-types/answers-types";

export const getAllAnswers = () => ({type: answersTypes.GET_ANSWERS});

export const clearAnswersStore = () => ({type: answersTypes.CLEAR_ANSWERS_STORE});

export const clearPlayerAnswers = () => ({type: answersTypes.CLEAR_PLAYER_ANSWERS});

export const createAnswer = (data) => ({type: answersTypes.CREATE_ANSWERS, payload: data});

export const getAnswersByUid = (data) => ({type: answersTypes.GET_ANSWERS_BY_UID, payload: data});

export const setPlayerAnswers = (data) => ({type: answersTypes.SET_PLAYER_ANSWERS, payload: data});

export const changeVideoStatus = (data) => ({type: answersTypes.CHANGE_VIDEO_STATUS, payload: data});

export const uploadVideoAnction = (data) => ({type: answersTypes.UPLOAD_ANSWER_VIDEO, payload: data});

export const getAnswersByQuestionsIds = (data) => ({type: answersTypes.GET_ANSWERS_BY_QUESTION_ID, payload: data});
