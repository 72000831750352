import Box from "@mui/material/Box";
import { CircularProgress } from "@mui/material";
import './LoadingMask.css';

const LoadingMask = ({isLoading}) => {
    return isLoading &&
        <Box className="loading__box" style={ {minHeight: '100vh'} }> <CircularProgress className="loading__mask"/>
        </Box>;
};

export default LoadingMask;
