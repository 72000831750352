import { questionsTypes } from "../action-types/questions-types";

export const getQuestions = () => ({ type: questionsTypes.GET_QUESTIONS });

export const getQuestionByUid = (data) => ({ type: questionsTypes.GET_QUESTIONS_BY_UID, payload: data });

export const clearQuestionsStore = () => ({ type: questionsTypes.CLEAR_QUESTIONS_STORE });

export const createQuestion = (data) => ({ type: questionsTypes.CREATE_QUESTION, payload: data });

export const getQuestionById = (data) => ({ type: questionsTypes.GET_QUESTIONS_BY_ID, payload: data });







