import { combineReducers } from "redux";
import { authReducer } from "./authReducer/authReducer";
import { tagsReducer } from "./tagsReducer/tagsReducer";
import { usersReducer } from "./usersReducer/userReducer";
import { recordsReducer } from "./reposReducers/reposReducer";
import { answersReducer } from "./answersReducer/answersReducer";
import { questionReducer } from "./questionReducer/questionReducer";

export const rootReducer = combineReducers({
    tagsReducer,
    authReducer,
    usersReducer,
    answersReducer,
    recordsReducer,
    questionReducer,
});