import { Navigate, Route, Routes } from 'react-router'
import Login from "../Authorization/Login";
import Registration from "../Authorization/Registration";

const AuthRouting = () => {

    return (
        <Routes>
            <Route path="/login" element={<Login/>}/>
            <Route path="/" element={<Navigate to="/login"/>}/>

            <Route path="/signup" element={<Registration/>}/>
            <Route path="/" element={<Navigate to="/signup"/>}/>
        </Routes>
    );
}


export default AuthRouting;