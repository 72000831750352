import { put } from 'redux-saga/effects'
import { sortByCreatedTime } from "../../services/helpers/sorts";
import { RecordsApi } from "../../services/api/recordsApi";
import { recordsTypes } from "../action-types/records-types";
import { filterFeeds } from "../../services/helpers/myDevice";

export function* getRecordsSaga() {
    try {
        yield put({type: recordsTypes.GET_RECORDS_REQUEST});
        const response = yield RecordsApi.getRecords();

        if (response && response.length) {
            yield put({type: recordsTypes.GET_RECORDS_SUCCESS, payload: response});
        } else {
            yield put({type: recordsTypes.GET_RECORDS_ERROR, payload: "Eroror"});
        }
    } catch (e) {
        yield put({type: recordsTypes.GET_RECORDS_ERROR, payload: e.message});
    }
}

export function* getRecordsByUidSaga() {
    try {
        yield put({type: recordsTypes.GET_RECORDS_BY_UID_REQUEST});
        const response = yield RecordsApi.getRecords();

        if (response.status === 200) {
            const feeds = sortByCreatedTime(filterFeeds(response.data.records));
            const notClosedVideos = feeds.filter(answer => !answer.fields.hasOwnProperty('isLockedVideo'));

            yield put({type: recordsTypes.GET_RECORDS_BY_UID_SUCCESS, payload: notClosedVideos});
        } else {
            yield put({type: recordsTypes.GET_RECORDS_BY_UID_ERROR, payload: response.error.message});
        }
    } catch (e) {
        yield put({type: recordsTypes.GET_RECORDS_BY_UID_ERROR, payload: e.message});
    }
}
