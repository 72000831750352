import { useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Modal } from "@mui/material";
import SkeletonWrapper from "../../components/SkeletonWrapper/SkeletonWrapper";
import loaderPoser from "../../assets/loadingImage.jpg";

const VideoAnswers = ({ video, thumbnail }) => {

    const [ open, setOpen ] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '85%',
        height: '80%',
        bgcolor: 'background.paper',
        boxShadow: 24,
        padding: 0
    };

    return (
        <Grid item xs={ 2 } sm={ 4 } md={ 4 }>
            {
                !video
                    ?
                    <SkeletonWrapper fromComponent='profile'/>
                    :
                    <div style={ {
                        'padding': '0',
                        'boxShadow': 'none',
                        'borderRadius': '10px !important',
                        'maxHeight': '180px !important',
                        'minHeight': '185px !important'
                    } }>
                        <video
                            className="video__player profile-answer_wrapper"
                            loop
                            style={ {
                                borderRadius: '10px',
                                backgroundImage: `url(${ thumbnail })`,
                                width: '100%',
                                backgroundSize: 'cover'
                            } }
                            onClick={ handleOpen }
                        >
                            <source type="video/mp4" src={ video }/>
                        </video>
                        <Modal
                            open={ open }
                            onClose={ handleClose }
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            className="modal-video_profile"
                        >
                            <Box sx={ style }>
                                <video
                                    className="video__player profile-answer"
                                    preload="true"
                                    loop
                                    autoPlay
                                    onClick={ handleOpen }
                                    poster={ loaderPoser }
                                    src={ video }
                                >
                                    <source type="video/mp4" src={ video }/>
                                </video>
                            </Box>
                        </Modal>
                    </div>
            }
        </Grid>
    )
};

export default VideoAnswers;
