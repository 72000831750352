import axios from "axios";
import { BASE_URL } from "./urls";

export class UsersApi {
    static async getUserById(userId) {
        try {
            const user = await axios.get(`${BASE_URL}${process.env.REACT_APP_BASE_ID}/users`, {
                headers: {
                    'Authorization': `Bearer ${process.env.REACT_APP_API_KEY}`,
                },
                params: {
                    filterByFormula: `{userId} = "${userId}"`
                }
            })

            if (user.data.records && user.data.records.length) {
                return user.data.records[0]
            }

        } catch (e) {
            console.log(e);
        }
    }
}
