import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useNavigate, useParams } from "react-router";
import MyAnswers from "../Users/MyAnswers";
import { useCallback, useEffect, useMemo } from "react";
import LoadingMask from "../../components/Loading/LoadingMask";
import { useDispatch, useSelector } from "react-redux";
import { getAllTags } from "../../store/action-creators/tags-actions";
import { clearAnswersStore, getAllAnswers, setPlayerAnswers } from "../../store/action-creators/answers-actions";

const SearchAnswers = () => {
    const {tagName} = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {answer, isLoading} = useSelector((state) => state.answersReducer);
    const {tags, isLoading: isTagsLoading} = useSelector((state) => state.tagsReducer);

    const title = tagName === 'recent' ? 'Recent answers' : `"${ tagName }" tag videos`;

    const normalizedAnswers = useMemo(() => {
        const normalizedData = {
            ids: [],
            data: {},
        }

        if (answer) {
            return answer.reduce((acc, answer) => {
                const {id} = answer;

                acc.data[id] = {
                    ...answer,
                }

                acc.ids.push(id);

                return acc;
            }, normalizedData);
        }

    }, [ answer ]);

    const normalizedTags = useMemo(() => {
        const normalizedData = {
            ids: [],
            data: {},
        }

        return tags.reduce((acc, tag) => {
            const {tagName, answerId} = tag.fields;

            if (!acc.data[tagName]) {
                acc.data[tagName] = {
                    tagName,
                    answerIds: answerId,
                }

                acc.ids.push(tagName);
            } else {
                const answers = new Set([ ...acc.data[tagName].answerIds, ...answerId ]);

                acc.data[tagName] = {
                    tagName,
                    answerIds: Array.from(answers),
                }
            }

            return acc;
        }, normalizedData);
    }, [ tags ]);

    const answers = useMemo(() => {
        if (tagName === 'recent') {
            return answer;
        }

        const tagAnswers = [];

        normalizedTags.data[tagName]?.answerIds.forEach((answerId) => {
            if (normalizedAnswers) {
                if (normalizedAnswers.data[answerId]) {
                    tagAnswers.push(normalizedAnswers.data[answerId]);
                }
            }
        });

        return tagAnswers;
    }, [ answer, tagName, normalizedTags, normalizedAnswers ]);

    const handleOpenVideo = useCallback((selectedAnswerId) => () => {
        dispatch(setPlayerAnswers({answers, selectedAnswerId}));
        navigate(`/answers-player/${ tagName }`);
    }, [ answers, dispatch, navigate, tagName ]);

    useEffect(() => {
        window.scrollTo({top: 0});

        dispatch(getAllAnswers());
        dispatch(getAllTags());

        return () => dispatch(clearAnswersStore());
    }, [ dispatch ]);

    if (isLoading || isTagsLoading) {
        return <LoadingMask isLoading={ isLoading || isTagsLoading }/>
    }

    return (
        <div style={ {paddingBottom: '100px', minHeight: '90vh', paddingTop: '100px'} }>
            <h2 style={ {textAlign: 'center', marginTop: -10} }>{ title }</h2>
            <Box sx={ {flexGrow: 1} } style={ {padding: "10px"} }>
                <Grid container spacing={ {xs: 2, md: 3} } columns={ {xs: 4, sm: 8, md: 12} }>
                    {
                        answers && answers.length ? answers.sort((a, b) => (
                            new Date(a.createdTime).valueOf() - new Date(b.createdTime).valueOf()
                        )).map(({id, fields}, index, elems) => (
                            <MyAnswers setOpened={ handleOpenVideo(id) } key={ id } fields={ fields }
                                       elems={ elems }
                                       slideIndex={ index }/>
                        )) : null
                    }
                </Grid>
            </Box>
        </div>
    )
}

export default SearchAnswers;
