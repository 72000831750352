import axios from 'axios';
import { VideoUploader } from "@api.video/video-uploader";

export const uploadVideo = async (file) => {
    
    const { data } = await axios.get('https://grandfriendsserver.herokuapp.com/get-token');

    const uploader = new VideoUploader({
        file,
        uploadToken: data.token,
        chunkSize: 1024 * 1024 * 5,
        retries: 10,
    });

    return uploader.upload();
}
