import { questionsTypes } from "../../action-types/questions-types.js";

const initialState = {
    records: [],
    isError: null,
    isLoading: false
};

export const questionReducer = (state = initialState, action) => {
    switch (action.type) {
        case questionsTypes.GET_QUESTIONS_REQUEST:
            return { ...state, isLoading: true }
        case questionsTypes.GET_QUESTIONS_SUCCESS:
            return { ...state, isLoading: false, isError: null, records: [ ...action.payload ] }
        case questionsTypes.GET_QUESTIONS_ERROR:
            return { ...state, isLoading: false, isError: action.payload }

        case questionsTypes.GET_QUESTIONS_BY_ID_REQUEST:
            return { ...state, isLoading: true }
        case questionsTypes.GET_QUESTIONS_BY_ID_SUCCESS:
            return { ...state, isLoading: false, isError: null, records: { ...action.payload } }
        case questionsTypes.GET_QUESTIONS_BY_ID_ERROR:
            return { ...state, isLoading: false, isError: action.payload }

        case questionsTypes.GET_QUESTIONS_BY_UID_REQUEST:
            return { ...state, isLoading: true }
        case questionsTypes.GET_QUESTIONS_BY_UID_SUCCESS:
            return { ...state, isLoading: false, isError: null, records: [ ...action.payload ] }
        case questionsTypes.GET_QUESTIONS_BY_UID_ERROR:
            return { ...state, isLoading: false, isError: action.payload }

        case questionsTypes.CLEAR_QUESTIONS_STORE_REQUEST:
            return { isLoading: true }
        case questionsTypes.CLEAR_QUESTIONS_STORE_SUCCESS:
            return { isLoading: false, isError: null, records: [] }
        case questionsTypes.CLEAR_QUESTIONS_STORE_ERROR:
            return { isLoading: false, isError: action.payload }

        default:
            return state
    }
};