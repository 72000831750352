import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { logoutUserAction } from "../../store/action-creators/auth-action";

const Logout = () => {
    const [ isAuthenticated, setAuthenticated ] = useState(localStorage.getItem("user"));

    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        setAuthenticated(localStorage.getItem("user"));
    }, [ isAuthenticated ]);

    const handleClick = useCallback(() => {
        localStorage.removeItem("user");
        navigate('/login');

        if (isAuthenticated) setAuthenticated(null);

        dispatch(logoutUserAction());
    }, [ isAuthenticated, dispatch, navigate ]);

    return (
        <Button style={ {
            padding: 0,
            textTransform: 'inherit',
            fontWeight: 400,
            fontSize: '1rem',
            lineHeight: '1.5',
            letterSpacing: ' 0.00938em',
            minWidth: "auto"
        } } color="inherit" onClick={ handleClick }>
            Logout
        </Button>
    );
};

export default Logout;