export const normalizedTagsResponse = (tags) => {
    const normalizedData = {
        ids: [],
        data: {},
    }

    return tags.reduce((acc, tag) => {
        const {tagName, answerId} = tag.fields;

        if (!acc.data[tagName]) {
            acc.data[tagName] = {
                tagName,
                answerIds: answerId,
            }

            acc.ids.push(tagName);
        } else {
            const answers = new Set([ ...acc.data[tagName].answerIds, ...answerId ]);

            acc.data[tagName] = {
                tagName,
                answerIds: Array.from(answers),
            }
        }

        return acc;
    }, normalizedData);
};

export const normalizedAnswersResponse = (answers) => {

    const normalizedData = {
        ids: [],
        data: {},
    }

    return answers.reduce((acc, answer) => {
        const {id} = answer;
        const {
            tagName,
            thumbnail,
            videoAnswer,
            questionText,
            responderName,
            NameResponder,
            isLockedVideo
        } = answer.fields;

        acc.data[id] = {
            id,
            tagName,
            thumbnail,
            videoAnswer,
            questionText,
            responderName,
            NameResponder,
            isLockedVideo
        }

        acc.ids.push(id);

        return acc;
    }, normalizedData);
};
