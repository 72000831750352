import { isAndroid, isDesktop, isIOS, } from "react-device-detect";

export const myDevice = () => {
    if (isIOS) return 'isIOS';
    if (isDesktop) return 'isDesktop';
    if (isAndroid) return 'isAndroid';

    return 'isDesktop';
}

export const filterFeeds = (arrayRecords) => {
    const { userId } = JSON.parse(localStorage.getItem("user"));
    if (!arrayRecords && !arrayRecords.length) return [];

    const feeds = [ ...arrayRecords ];

    return feeds.filter(el => el.fields.responder[0] !== userId);
}

export const filterMyAnswers = (arrayAnswers) => {
    if (!arrayAnswers && !arrayAnswers.length) return [];

    const feeds = [ ...arrayAnswers ];
    const device = myDevice();

    if (device === "isIOS") {
        return feeds.filter(el => el.fields.device === device) || [];
    }

    return feeds;
}