import { useEffect } from "react";
import Grid from "@mui/material/Grid";
import { Box, Card } from "@mui/material";
import VideoAnswers from './VideoAnswers';
import LoadingMask from '../../components/Loading/LoadingMask'
import Typography from "@mui/material/Typography";
import { useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getAnswersByQuestionsIds } from "../../store/action-creators/answers-actions.js";
import { clearQuestionsStore, getQuestionById } from "../../store/action-creators/questions-actions";
import "./Questions.css";

const AnswersOnQuestion = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {records, isLoading} = useSelector(state => state.questionReducer);
    const {answer} = useSelector(state => state.answersReducer);

    const {userId: currentUserId} = JSON.parse(localStorage.getItem("user"));
    const {question: questionText, questioner, user} = records;

    const moveToUser = () => {
        const userId = user[0];

        if (currentUserId === userId) {
            navigate('/profile')
        } else {
            navigate(`/user/${ userId }`);
        }
    };

    useEffect(() => {
        window.scrollTo({top: 0});
        dispatch(getQuestionById(id));
        dispatch(getAnswersByQuestionsIds(id));

        return () => dispatch(clearQuestionsStore())
    }, [ dispatch, id ]);

    if (isLoading) {
        return <LoadingMask isLoading={ isLoading }/>
    }

    if (!answer || answer.length < 0) {
        return <div>
            <h3 style={ {textAlign: 'center'} }>List of answers is empty.</h3>
        </div>
    }

    return (
        <div style={ {minHeight: '100vh'} }>
            <Card className="question-card question-card_answers-page" sx={ {minWidth: 275, maxWidth: 400} }>
                <Typography variant="body2"
                            className="questioner questioner_answers-page"
                            sx={ {mb: 1.5} }
                            color="text.secondary"
                            onClick={ moveToUser }
                >
                    @{ questioner }
                </Typography>
                <Typography variant="h5">
                    { questionText }
                </Typography>
            </Card>

            { answer && answer.length ? (
                <div style={ {paddingTop: '140px'} }>
                    <Grid
                        style={ {
                            paddingLeft: '6px',
                            paddingRight: '6px',
                            paddingBottom: '100px',
                            height: '100%'
                        } }
                        container
                        spacing={ {xs: 1, md: 2} }
                        columns={ {xs: 4, sm: 8, md: 12} }
                    >
                        {
                            answer.map(({fields}) => (
                                <VideoAnswers
                                    video={ fields.videoAnswer }
                                    thumbnail={ fields.thumbnail }
                                    key={ fields.videoAnswer.slice(-39, -15) }
                                />
                            ))
                        }
                    </Grid>
                </div>
            ) : (
                <Box sx={ {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: 'calc(100vh - 150px)',
                } }>
                    <Typography variant="h6">
                        There is not answers for this question
                    </Typography>
                </Box>
            ) }
        </div>
    )
};

export default AnswersOnQuestion;
