import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { changeVideoStatus } from "../../store/action-creators/answers-actions";

const LockedVideoIcons = ({isLocked, setVideoStatus, answerId}) => {
    const dispatch = useDispatch();

    const handleStatusVideo = useCallback((status) => () => {
        dispatch(changeVideoStatus({status, answerId}))
        setVideoStatus(status);
    }, [ dispatch, answerId ])

    if (isLocked) {
        return <LockOutlinedIcon onClick={ handleStatusVideo(false, answerId) } className="action__icon"/>
    } else {
        return <LockOpenOutlinedIcon onClick={ handleStatusVideo(true, answerId) } className="action__icon"/>
    }
}

export default LockedVideoIcons;