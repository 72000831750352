import { useSelector } from 'react-redux';
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import { useNavigate } from "react-router";
import userImage from "../../assets/user.png"
import Typography from "@mui/material/Typography";
import { Box, Card, CardActions, CardContent } from "@mui/material";
import "./Questions.css";

const Question = ({q, openAnswerCamera, uploadLocalFile}) => {
    const navigate = useNavigate();
    const {question, questioner, answers, user} = q.fields;
    const {isUploadingVideo} = useSelector((state) => state.answersReducer);
    const checkQuestioner = questioner && questioner.length ? questioner[0] : null;
    const {userId: currentUserId} = JSON.parse(localStorage.getItem("user"));

    const selectQuestion = (e) => {
        if (e.target.localName === 'img') {
            const userId = user[0];

            if (currentUserId === userId) {
                navigate('/profile')
            } else {
                navigate(`/user/${ userId }`);
            }

        } else if (e.target.type !== 'button') {
            const {id} = q;
            navigate(`/questions/${ id }`)
        }
    };

    return (
        <Card onClick={ selectQuestion } className="question-card" sx={ {minWidth: 275, maxWidth: 400} }>
            <CardContent style={ {cursor: 'pointer'} }>
                <Typography className="amount_answers" sx={ {mb: 1.5} } color="text.secondary">
                    { answers && answers.length ? `Number of responses: ${ answers.length }` : null }
                </Typography>
                <Typography variant="body2" className="question-text">
                    { question }
                </Typography>
                <Box className="questioner" sx={ {mb: 1.5} } color="text.secondary">
                    <Avatar className="avatar-questioner" alt="user" src={ userImage }>{ checkQuestioner }</Avatar>
                    { checkQuestioner }
                </Box>
            </CardContent>
            <CardActions className="answer-card">
                <label htmlFor={ `contained-button-file_${ q.id }` }>
                    <Button className={ isUploadingVideo ? 'disabled-button' : 'answer-button' }
                            disabled={ isUploadingVideo }
                            variant="contained"
                            onClick={ () => openAnswerCamera(q.id) }>
                        Record
                    </Button>
                </label>
                <Button className={ isUploadingVideo ? 'disabled-button_local' : 'local-file-button' }
                        disabled={ isUploadingVideo }
                        variant="contained"
                        onClick={ () => {
                            openAnswerCamera(q.id)
                            uploadLocalFile(true)
                        } }>
                    Select from library
                </Button>
            </CardActions>
        </Card>
    );
};

export default Question;