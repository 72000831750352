import './App.css';
import Routing from "./components/Routing/Routing";
import {createTheme, ThemeProvider} from '@mui/material/styles';
import buffer from 'buffer';

window.Buffer = window.Buffer || buffer.Buffer;
const theme = createTheme();

const App = () => {

    return (
        <div className="app app_questions-page">
            <ThemeProvider theme={theme}>
                <Routing/>
            </ThemeProvider>
        </div>
    );
}

export default App;
