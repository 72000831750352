import {useState, useEffect, useCallback} from 'react'

const VideoTimer = ({ timeLimit, onStopRecording }) => {
    const [time, setTime] = useState({
        human: 0,
        seconds: 0
    });

    const getSeconds = useCallback((nextSeconds) => {
        const minutes = Math.floor(nextSeconds / 60);

        const humanTime = minutes !== 0
            ? `${minutes}:${pad(nextSeconds - minutes * 60)}`
            : `${nextSeconds - minutes * 60}s`;

        return {
            seconds: nextSeconds,
            human: humanTime
        }
    }, []);

    useEffect(() => {
        const nextSeconds = timeLimit ? timeLimit / 1000 : 0;
        getSeconds(nextSeconds);
    }, [getSeconds, timeLimit]);

    const pad = (unit) => {
        let str = '' + unit;
        let pad = '00';
        return pad.substring(0, pad.length - str.length) + str;
    }

    useEffect(() => {
        const timer = setInterval(() => {
            const { seconds } = time;

            const nextSeconds = timeLimit ? seconds - 1 : seconds + 1

            const nextState = getSeconds(nextSeconds)
            setTime({
                human: nextState.human,
                seconds: nextState.seconds
            })
        }, 1000);

        return () => clearInterval(timer);
    }, [time, getSeconds, timeLimit]);

     if (time.seconds >= 300) {
         onStopRecording();
     }

        return (
            <div>
                {time.human}
            </div>
        )
}

export default VideoTimer;
