import Box from "@mui/material/Box";
import { useNavigate } from "react-router";
import Typography from "@mui/material/Typography";
import ReplyIcon from '@mui/icons-material/Reply';
import { useEffect, useRef, useState } from "react";
import CommentIcon from '@mui/icons-material/Comment';
import FavoriteIcon from '@mui/icons-material/Favorite';
import useElementOnScreen from "../../services/hooks/useElementOnScreen";
import LockedVideoIcons from "../../screens/Profile/LockedVideoIcons";
import './Feed.css';

const Feed = ({feed, scrollTo, component}) => {

    const videoRef = useRef(null);
    const ref = useRef(null);
    const navigate = useNavigate()
    const {
        videoAnswer,
        questionText,
        NameResponder: userName,
        responderName: responderId,
        tagName,
        thumbnail,
        isLockedVideo,
        answerId
    } = feed;

    const [ playing, setPlaying ] = useState(false);
    const [ videoStatus, setVideoStatus ] = useState(isLockedVideo ? isLockedVideo : false);

    const options = {
        root: null,
        threshold: 0.9,
        rootMargin: '0px'
    };

    const isVisible = useElementOnScreen(options, videoRef);

    const moveToUser = () => {
        if (responderId[0]) {
            navigate(`/user/${ responderId[0] }`);
        }
    }

    useEffect(() => {
        if (isVisible) {
            if (!playing) {
                videoRef.current.play();
                setPlaying(true)
            }
        } else {
            if (playing) {
                videoRef.current.pause();
                setPlaying(false)
            }
        }
    }, [ isVisible, playing, feed ]);

    useEffect(() => {
        videoRef.current.onprogress = () => {
            const endBuf = videoRef.current.buffered.end(0);
            parseInt(((endBuf / videoRef.current.duration) * 100));
        }
    }, [ videoRef ]);

    useEffect(() => {
        if (scrollTo && ref && ref.current) {
            ref.current.scrollIntoView({behavior: 'auto', block: 'start'})
        }
    }, [ ref, scrollTo ]);

    return (
        <div ref={ ref } className="video-container">
            {
                videoAnswer && videoAnswer.length ?
                    <video
                        className='video__player'
                        loop
                        muted={ true }
                        playsInline
                        controls={ false }
                        style={ {
                            backgroundImage: `url(${ thumbnail })`,
                            backgroundSize: 'cover'
                        }
                        }
                        ref={ videoRef }
                    >
                        <source type="video/mp4" src={ videoAnswer }/>
                    </video>
                    :
                    null
            }
            <Box className="question__box">
                <Typography>{ questionText && questionText.length ? questionText[0] : null }</Typography>
                <Box className="tags-wrapper">
                    {
                        tagName && tagName.length ? tagName.map((tag, index) => {
                            return (
                                <p key={ index } className="tag">#{ tag }</p>
                            )
                        }) : null
                    }
                </Box>
            </Box>
            <Box className="responder__box">
                <Typography
                    onClick={ moveToUser }
                    className="responder"
                >
                    { userName && userName.length ? '@' + userName[0] : null }
                </Typography>
            </Box>
            <Box className="icons__container">

                { component === 'profile'
                    &&
                    <Box className="icons__box">
                        <LockedVideoIcons
                            isLocked={ videoStatus }
                            setVideoStatus={ setVideoStatus }
                            answerId={ answerId }
                        />
                    </Box>
                }
                <Box className="icons__box">
                    <FavoriteIcon fontSize="large" className="action__icon"/>
                </Box>
                <Box className="icons__box">
                    <CommentIcon fontSize="large" className="action__icon"/>
                </Box>
                <Box className="icons__box">
                    <ReplyIcon fontSize="large" className="action__icon reply__icon"/>
                </Box>
            </Box>
        </div>
    );
};

export default Feed;
