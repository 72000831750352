export const loginTypes = {
    LOGIN_USER: 'LOGIN_USER',
    LOGIN_ERROR: 'LOGIN_ERROR',
    LOGIN_REQUEST: 'LOGIN_REQUEST',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS'
}

export const usersRegistrationTypes = {
    REGISTRATION_USER: 'REGISTRATION_USER',
    REGISTRATION_ERROR: 'REGISTRATION_ERROR',
    REGISTRATION_REQUEST: 'REGISTRATION_REQUEST',
    REGISTRATION_SUCCESS: 'REGISTRATION_SUCCESS'
}

export const logoutTypes = {
    LOGOUT_USER: 'LOGOUT_USER'
}