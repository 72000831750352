import Feed from "../../components/Feeds/Feed";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRecordsByUserId } from "../../store/action-creators/records-actions";
import LoadingMask from "../../components/Loading/LoadingMask";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const Home = () => {
    const dispatch = useDispatch();
    const {records, isLoading} = useSelector(state => state.recordsReducer);

    useEffect(() => {
        window.scrollTo({top: 0});
        const {userId} = JSON.parse(localStorage.getItem("user"));
        dispatch(getRecordsByUserId(userId));
    }, [ dispatch ]);

    if (!records.length && !isLoading) {
        return <Container className="no-content">
            <Box>
                <Typography component="div">
                    You don't have any posts
                </Typography>
            </Box>
        </Container>
    }

    return (
        <div className="app__videos">
            {
                !isLoading
                    ?
                    records.map((record, index) => <Feed feed={ record.fields } key={ index }/>)
                    :

                    <LoadingMask isLoading={ isLoading }/>
            }
        </div>
    );
};

export default Home;
