import axios from "axios";
import { BASE_URL } from "./urls";

export class QuestionsApi {
    static async getQuestions() {
        return axios.get(`${BASE_URL}${process.env.REACT_APP_BASE_ID}/questions`, {
            headers: {
                'Authorization': `Bearer ${process.env.REACT_APP_API_KEY}`
            }
        })
    };

    static async getQuestionsById(id) {
        return axios.get(`${BASE_URL}${process.env.REACT_APP_BASE_ID}/questions/${id}`, {
            headers: {
                'Authorization': `Bearer ${process.env.REACT_APP_API_KEY}`
            }
        })
    };

    static async getQuestionsByUserId(userId) {

        return axios.get(`${BASE_URL}${process.env.REACT_APP_BASE_ID}/questions`, {
            headers: {
                'Authorization': `Bearer ${process.env.REACT_APP_API_KEY}`
            },
            params: {
                filterByFormula: `{userIdQuestioner} = "${userId}"`
            }
        })
    };

    static async createQuestion({ question }) {
        const { userId } = JSON.parse(localStorage.getItem('user'));
        const questionData = {
            fields: {
                "question": question,
                "user": [ userId ],
            }
        }
        return axios.post(`${BASE_URL}${process.env.REACT_APP_BASE_ID}/questions`, questionData, {
            headers: {
                'Authorization': `Bearer ${process.env.REACT_APP_API_KEY}`
            }
        })
    }
}