import {Backdrop, Fade, Modal, TextField} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import * as React from "react";
import {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import LoadingMask from "../../components/Loading/LoadingMask";
import ProgressBar from '../../components/Loading/ProgressBar';
import AddTag from '../../components/Tags/AddTag.js';
import {FromVideoRecorder} from "../../components/VideoRecorder/VideoRecorder";
import {uploadVideoAnction} from "../../store/action-creators/answers-actions";
import {clearQuestionsStore, createQuestion, getQuestions,} from "../../store/action-creators/questions-actions";
import Question from "./Question";
import "./Questions.css";

const style = {
    p: 4,
    top: '50%',
    left: '50%',
    width: '50%',
    boxShadow: 24,
    minWidth: 200,
    position: 'absolute',
    bgcolor: 'background.paper',
    transform: 'translate(-50%, -50%)',
};

const Questions = () => {
    const [open, setOpen] = useState(false);
    const [tagPage, setTagPage] = useState(false);
    const [videoFile, setVideoFile] = useState(null);
    const [questionId, setQuestionId] = useState(null);
    const [isOpenedAnswer, setOpenedAnswer] = useState(false);
    const [uploadLocalFile, seUploadLocalFile] = useState(false);
    const [questionForm, setQuestionForm] = useState({
        question: ''
    });

    const {isUploadingVideo} = useSelector((state) => state.answersReducer);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const dispatch = useDispatch();
    const {records, isLoading} = useSelector(state => state.questionReducer);

    const handleSubmit = useCallback((event) => {
        event.preventDefault();
        dispatch(createQuestion(questionForm));
        setOpen(false);
    }, [dispatch, questionForm]);

    const handlerOnChange = useCallback((event) => {
        setQuestionForm({
            ...questionForm,
            [event.target.name]: event.target.value
        });
    }, [questionForm]);

    const openAnswerCamera = useCallback((id) => {
        if (id) setQuestionId(id);
        setOpenedAnswer(true);
    }, []);

    const uploadVideoToServer = async (file, tags) => {
        console.log(tags);
        if (tags && tags.length) {
            dispatch(uploadVideoAnction({file, questionId, tags}));
        } else {
            dispatch(uploadVideoAnction({file, questionId}));
        }

        setOpenedAnswer(false);
        if (tagPage) {
            setTagPage(false);
        }
    };

    useEffect(() => {
        window.scrollTo({top: 0});
        dispatch(getQuestions());

        return () => dispatch(clearQuestionsStore())
    }, [dispatch]);

    const getVideoFile = (file) => {
        setOpenedAnswer(false);
        setTagPage(true);
        setVideoFile(file);
    };

    if (tagPage && videoFile) {
        return (
            <AddTag
                video={videoFile}
                uploadVideoToServer={uploadVideoToServer}
                setOpenedAnswer={setOpenedAnswer}
                setTagPage={setTagPage}
            />
        );
    }

    if (isLoading) {
        return <LoadingMask isLoading={isLoading}/>
    }

    return (
        <div className="questions">
            {
                isUploadingVideo
                    ?
                    <Box className="box-actions-buttons progress-bar">
                        <h5 className="uploading-text">Uploading video...</h5>
                        <ProgressBar/>
                    </Box>
                    :
                    <Box className="box-actions-buttons">
                        <Button onClick={() => openAnswerCamera(null)}>Record Free Video</Button>
                        <Button onClick={handleOpen}>+Add New Question</Button>
                    </Box>
            }
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <TextField
                            id="standard-basic"
                            label="Standard"
                            variant="standard"
                            name="question"
                            value={questionForm.question}
                            onChange={handlerOnChange}
                        />
                        <Button
                            type="button"
                            onClick={handleSubmit}
                            fullWidth
                            variant="contained"
                            sx={{mt: 3, mb: 2}}
                        >
                            Add
                        </Button>
                    </Box>
                </Fade>
            </Modal>

            {
                isOpenedAnswer
                    ?
                    (
                        <div className="videoRecorderWrapper">
                            <FromVideoRecorder uploadVideoToServer={uploadVideoToServer}
                                               setOpenedAnswer={setOpenedAnswer}
                                               setTagPage={setTagPage}
                                               uploadLocalFile={uploadLocalFile}
                                               openTagsPage={getVideoFile}
                            />
                        </div>
                    )
                    : (
                        <div style={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            textAlign: 'center',
                            flexDirection: 'column',
                            alignItems: 'center',
                            backgroundColor: 'white',
                        }}>
                            {records && records.length ? records.map((q, index) => (
                                <Question
                                    q={q}
                                    key={index}
                                    openAnswerCamera={openAnswerCamera}
                                    uploadLocalFile={seUploadLocalFile}
                                />
                            )) : null
                            }
                        </div>
                    )
            }
        </div>
    );
};

export default Questions;
