import { useCallback, useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router";
import SearchIcon from '@mui/icons-material/Search';
import Autocomplete from "@mui/material/Autocomplete";
import { useDispatch, useSelector } from "react-redux";
import VideoModal from "../../components/Modal/VideoModal";
import LoadingMask from "../../components/Loading/LoadingMask";
import { InputAdornment, Stack, TextField } from "@mui/material";
import { AnswersHelper } from "../../services/helpers/answersHelper";
import { getAllTags } from "../../store/action-creators/tags-actions";
import { clearAnswersStore, getAllAnswers, setPlayerAnswers } from "../../store/action-creators/answers-actions";
import { normalizedAnswersResponse, normalizedTagsResponse } from "../../services/helpers/normolizedResponse";
import './SearchScreen.css';

const SearchScreen = () => {
    const [ inputValue, setInputValue ] = useState('#home');
    const [ answers, setAnswers ] = useState([]);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {answer, isLoading: isAnswersLoading} = useSelector((state) => state.answersReducer);
    const {tags, isLoading: isTagsLoading} = useSelector((state) => state.tagsReducer);
    const availableVideoIdByTags = [];

    if (tags && answer) {
        for (const tag of tags) {
            for (const ans of answer) {
                if (tag.fields.answerId[0] === ans.id) availableVideoIdByTags.push(tag)
            }
        }
    }

    const normalizedAnswers = useMemo(() => {
        return normalizedAnswersResponse(answers);
    }, [ answers ]);

    const normalizedTags = useMemo(() => {
        return normalizedTagsResponse(availableVideoIdByTags);
    }, [ tags, availableVideoIdByTags ]);

    const handleOnChange = (event, tag) => {
        navigate(`/search/${ tag }`);
    };

    const handleClick = () => {
        if (inputValue) {
            navigate(`/search/${ inputValue }`);
        }

        setInputValue('');
    };

    const handleOpenVideo = useCallback((selectedAnswerId, answerIds, tagName) => () => {
        const selectedAnswers = answers.filter((answer) => answerIds.includes(answer.id));

        dispatch(setPlayerAnswers({answers: selectedAnswers, selectedAnswerId}));
        navigate(`/answers-player/${ tagName }`);
    }, [ answers, dispatch, navigate ]);

    useEffect(() => {
        window.scrollTo({top: 0});

        dispatch(getAllAnswers());
        dispatch(getAllTags());

        return () => dispatch(clearAnswersStore())
    }, [ dispatch ]);

    useEffect(() => {
        if (answer) {
            const {sortedArr} = AnswersHelper.getRecentAnswers(answer);

            setAnswers([ ...sortedArr ]);
        }
    }, [ answer ]);

    if (isTagsLoading || isAnswersLoading) {
        return <LoadingMask isLoading={ isAnswersLoading }/>
    }

    const renderVideoSection = (title, answerIds) => {
        const tagName = title === 'Recent' ? 'recent' : title;

        return (
            <Box className="recent_wrapper">
                <div>
                    <Box className="recent_buttons">
                        <div className='recent-icon'>{ title }</div>
                        <button
                            className='recent-button'
                            onClick={ () => navigate(`/search/${ tagName }`) }
                        >
                            See all
                        </button>
                    </Box>
                    <Stack direction="row" className="videos-wrapper">
                        { answerIds.slice(0, 5).map((id) => {
                            return (
                                <VideoModal
                                    key={ id }
                                    setOpened={ handleOpenVideo(id, answerIds, tagName) }
                                    srcVideo={ normalizedAnswers.data[id]?.videoAnswer }
                                    srcThumbnail={ normalizedAnswers.data[id]?.thumbnail }
                                />
                            )
                        }) }
                    </Stack>
                </div>
            </Box>
        )
    }

    if (isTagsLoading || isAnswersLoading) {
        return null;
    }

    return (
        <Box style={ {paddingBottom: '100px', minHeight: '100vh', paddingTop: '35px '} }>
            <Box className='input-box'>
                <Autocomplete
                    freeSolo
                    id="free-solo-2-demo"
                    className='search-input'
                    onChange={ handleOnChange }
                    options={ normalizedTags.ids }
                    renderInput={ (params) => (
                        <TextField
                            { ...params }
                            label="Search input"
                            InputProps={ {
                                ...params.InputProps,
                                type: 'search',
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <SearchIcon onClick={ handleClick }/>
                                    </InputAdornment>
                                )
                            } }
                        />
                    ) }
                />
            </Box>

            { renderVideoSection('Recent', normalizedAnswers.ids) }

            { normalizedTags.ids.map((tagName) => {
                return (
                    <div key={ tagName }>
                        { renderVideoSection(tagName, normalizedTags.data[tagName].answerIds) }
                    </div>
                )
            }) }
        </Box>
    )
};

export default SearchScreen;
