export const tagsTypes = {
    CREATE_TAG: 'CREATE_TAG',
    CREATE_TAG_ERROR: 'CREATE_TAG_ERROR',
    CREATE_TAG_REQUEST: 'CREATE_TAG_REQUEST',
    CREATE_TAG_SUCCESS: 'CREATE_TAG_SUCCESS',

    GET_TAGS: 'GET_TAGS',
    GET_TAGS_ERROR: 'GET_TAGS_ERROR',
    GET_TAGS_REQUEST: 'GET_TAGS_REQUEST',
    GET_TAGS_SUCCESS: 'GET_TAGS_SUCCESS'
}
