import { answersTypes } from "../../action-types/answers-types";

const initialState = {
    answer: null,
    isError: null,
    isLoading: false,
    fromComponent: null,
    playerAnswers: null,
    isUploadingVideo: false,
    playerSelectedAnswerId: null,
}

export const answersReducer = (state = initialState, action) => {
    switch (action.type) {
        case answersTypes.GET_ANSWERS_REQUEST:
            return {...state, isError: false, isLoading: true}
        case answersTypes.GET_ANSWERS_SUCCESS:
            return {...state, isError: false, isLoading: false, answer: [ ...action.payload ]}
        case answersTypes.GET_ANSWERS_ERROR:
            return {...state, isError: true, isLoading: false}

        case answersTypes.CREATE_ANSWERS_REQUEST:
            return {...state, isError: false, isLoading: true}
        case answersTypes.CREATE_ANSWERS_SUCCESS:
            return {...state, isError: false, isLoading: false}
        case answersTypes.CREATE_ANSWERS_ERROR:
            return {...state, isError: action.payload, isLoading: false}

        case answersTypes.GET_ANSWERS_BY_UID_REQUEST:
            return {...state, isError: false, isLoading: true}
        case answersTypes.GET_ANSWERS_BY_UID_SUCCESS:
            return {...state, isError: false, isLoading: false, answer: [ ...action.payload ]}
        case answersTypes.GET_ANSWERS_BY_UID_ERROR:
            return {...state, isError: true, isLoading: false}

        case answersTypes.GET_ANSWERS_BY_QUESTION_ID_REQUEST:
            return {...state, isError: false, isLoading: true}
        case answersTypes.GET_ANSWERS_BY_QUESTION_ID_SUCCESS:
            return {...state, isError: false, isLoading: false, answer: [ ...action.payload ]}
        case answersTypes.GET_ANSWERS_BY_QUESTION_ID_ERROR:
            return {...state, isError: true, isLoading: false}

        case answersTypes.UPLOAD_ANSWER_VIDEO_REQUEST:
            return {...state, isError: false, isUploadingVideo: true}
        case answersTypes.UPLOAD_ANSWER_VIDEO_SUCCESS:
            return {...state, isError: false, isUploadingVideo: false}
        case answersTypes.UPLOAD_ANSWER_VIDEO_ERROR:
            return {...state, isError: true, isUploadingVideo: false}

        case answersTypes.SET_PLAYER_ANSWERS:
            return {
                ...state,
                playerAnswers: action.payload.answers,
                playerSelectedAnswerId: action.payload.selectedAnswerId,
                fromComponent: action.payload.component
            }

        case answersTypes.CLEAR_PLAYER_ANSWERS:
            return {
                ...state,
                playerAnswers: null,
                playerSelectedAnswerIndex: null,
                fromComponent: null
            }

        case answersTypes.CHANGE_VIDEO_STATUS_REQUEST:
            return {...state, isLoading: true}
        case answersTypes.CHANGE_VIDEO_STATUS_SUCCESS:
            return {...state, isError: false, isLoading: false, answer: [ ...action.payload ]}
        case answersTypes.CHANGE_VIDEO_STATUS_ERROR:
            return {...state, isLoading: false, isError: action.payload}

        case answersTypes.CLEAR_ANSWERS_STORE_REQUEST:
            return {...state, isLoading: true}
        case answersTypes.CLEAR_ANSWERS_STORE_SUCCESS:
            return {...state, isLoading: false, isError: null, answer: null}
        case answersTypes.CLEAR_ANSWERS_STORE_ERROR:
            return {...state, isLoading: false, isError: action.payload}
        default:
            return state;
    }
}
