import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { rootReducer } from './reducers';
import createSagaMiddleware from 'redux-saga';
import { rootSaga } from './rootSagas';

let store;
const sagaMiddleware = createSagaMiddleware();

if (process.env.NODE_ENV === 'production') {
    store = createStore(rootReducer, applyMiddleware(sagaMiddleware));
} else {
    store = createStore(rootReducer, composeWithDevTools(
        applyMiddleware(sagaMiddleware)
    ));
}

sagaMiddleware.run(rootSaga);

export default store;