export const answersTypes = {
    GET_ANSWERS: 'GET_ANSWERS',
    GET_ANSWERS_ERROR: 'GET_ANSWERS_ERROR',
    GET_ANSWERS_REQUEST: 'GET_ANSWERS_REQUEST',
    GET_ANSWERS_SUCCESS: 'GET_ANSWERS_SUCCESS',

    CREATE_ANSWERS: 'CREATE_ANSWERS',
    UPLOAD_ANSWER_VIDEO: 'UPLOAD_ANSWER_VIDEO',
    CREATE_ANSWERS_ERROR: 'CREATE_ANSWERS_ERROR',
    CREATE_ANSWERS_REQUEST: 'CREATE_ANSWERS_REQUEST',
    CREATE_ANSWERS_SUCCESS: 'CREATE_ANSWERS_SUCCESS',
    UPLOAD_ANSWER_VIDEO_ERROR: 'UPLOAD_ANSWER_VIDEO_ERROR',
    UPLOAD_ANSWER_VIDEO_REQUEST: 'UPLOAD_ANSWER_VIDEO_REQUEST',
    UPLOAD_ANSWER_VIDEO_SUCCESS: 'UPLOAD_ANSWER_VIDEO_SUCCESS',

    GET_ANSWERS_BY_UID: 'GET_ANSWERS_BY_UID',
    GET_ANSWERS_BY_UID_ERROR: 'GET_ANSWERS_BY_UID_ERROR',
    GET_ANSWERS_BY_UID_REQUEST: 'GET_ANSWERS_BY_UID_REQUEST',
    GET_ANSWERS_BY_UID_SUCCESS: 'GET_ANSWERS_BY_UID_SUCCESS',

    GET_ANSWERS_BY_QUESTION_ID: 'GET_ANSWERS_BY_QUESTION_ID',
    GET_ANSWERS_BY_QUESTION_ID_ERROR: 'GET_ANSWERS_BY_QUESTION_ID_ERROR',
    GET_ANSWERS_BY_QUESTION_ID_REQUEST: 'GET_ANSWERS_BY_QUESTION_ID_REQUEST',
    GET_ANSWERS_BY_QUESTION_ID_SUCCESS: 'GET_ANSWERS_BY_QUESTION_ID_SUCCESS',

    CLEAR_ANSWERS_STORE: 'CLEAR_ANSWERS_STORE',
    CLEAR_ANSWERS_STORE_ERROR: 'CLEAR_ANSWERS_STORE_ERROR',
    CLEAR_ANSWERS_STORE_REQUEST: 'CLEAR_ANSWERS_STORE_REQUEST',
    CLEAR_ANSWERS_STORE_SUCCESS: 'CLEAR_ANSWERS_STORE_SUCCESS',

    SET_PLAYER_ANSWERS: 'SET_PLAYER_ANSWERS',
    CLEAR_PLAYER_ANSWERS: 'CLEAR_PLAYER_ANSWERS',

    CHANGE_VIDEO_STATUS: 'CHANGE_VIDEO_STATUS',
    CHANGE_VIDEO_STATUS_ERROR: 'CHANGE_VIDEO_STATUS_ERROR',
    CHANGE_VIDEO_STATUS_REQUEST: 'CHANGE_VIDEO_STATUS_REQUEST',
    CHANGE_VIDEO_STATUS_SUCCESS: 'CHANGE_VIDEO_STATUS_SUCCESS',
}
