import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {useState} from "react";
import {browserName} from "react-device-detect";
import VideoRecorder from "react-video-recorder";
import './VideoRecorder.css';
import VideoTimer from "./VideoTimer";

export const FromVideoRecorder = ({
                                      openTagsPage,
                                      uploadLocalFile,
                                      isVideoUploading,
                                      uploadVideoToServer,
                                      setOpenedAnswer
                                  }) => {
    const [video, setVideo] = useState(null);

    return (
        <>
            {isVideoUploading &&
                <div style={{
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    opacity: 0.6,
                    display: 'flex',
                    zIndex: 9999999,
                    color: "#FFFFFF",
                    position: 'absolute',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: '#000000',
                }}>
                    Uploading...
                </div>
            }
            <VideoRecorder
                countdownTime={0}
                isOnInitially={true}
                useVideoInput={uploadLocalFile}
                mimeType={browserName === "Mobile Safari" ? "video/mp4" : "video/webm;codecs=vp8,opus"}
                onRecordingComplete={(videoBlob) => {
                    setVideo(videoBlob)
                }}
                renderActions={(props) => {
                    return <Actions {...props} uploadVideoToServer={uploadVideoToServer}
                                    openTagsPage={openTagsPage} video={video}
                                    isVideoUploading={isVideoUploading}
                                    setOpenedAnswer={setOpenedAnswer}
                    />
                }}
            />
        </>
    );
};

const Actions = ({
                     t,
                     isVideoInputSupported,
                     isInlineRecordingSupported,
                     thereWasAnError,
                     isRecording,
                     isCameraOn,
                     streamIsReady,
                     isConnecting,
                     isRunningCountdown,
                     isReplayingVideo,
                     timeLimit,
                     onTurnOnCamera,
                     onOpenVideoInput,
                     onStartRecording,
                     onStopRecording,
                     setOpenedAnswer,
                     openTagsPage,
                     video,
                 }) => {

    const renderContent = () => {
        const shouldUseVideoInput =
            !isInlineRecordingSupported && isVideoInputSupported

        if (
            (!isInlineRecordingSupported && !isVideoInputSupported) ||
            thereWasAnError ||
            isConnecting ||
            isRunningCountdown
        ) {
            return null
        }

        if (isReplayingVideo) {
            return (
                <>
                    <div className='recorder-tags-wrapper'>
                        <button className='tag-button' onClick={() => openTagsPage(video)}>
                            Tap to post
                        </button>
                    </div>
                    <div style={{
                        position: "absolute",
                        top: '-575px',
                        left: '20px',
                    }}>
                        <ArrowBackIosIcon
                            style={{cursor: "pointer"}}
                            onClick={() => setOpenedAnswer(false)}/>
                    </div>
                </>

            )
        }

        if (isRecording) {
            return (
                <>
                    <button
                        className='stop-record_button'
                        type='button'
                        onClick={onStopRecording}
                        data-qa='stop-recording'
                    >
                        Recording
                    </button>
                    <div style={{
                        position: "absolute",
                        top: '-575px',
                        left: '20px',
                    }}>
                        <ArrowBackIosIcon style={{cursor: "pointer"}} onClick={() => setOpenedAnswer(false)}/>
                    </div>
                </>

            )
        }

        if (isCameraOn && streamIsReady) {
            return (
                <>
                    <button
                        className='start-record_button'
                        color="error"
                        type='button'
                        onClick={onStartRecording}
                        data-qa='start-recording'
                    >
                        Record
                    </button>
                    <div style={{
                        position: "absolute",
                        top: '-575px',
                        left: '20px',
                    }}>
                        <ArrowBackIosIcon style={{cursor: "pointer"}} onClick={() => setOpenedAnswer(false)}/>
                    </div>
                </>
            )
        }

        return shouldUseVideoInput ? (
            <>
                <button type='button' onClick={onOpenVideoInput} data-qa='open-input'>
                    {t('Record a video')}
                </button>
            </>
        ) : (
            <>
                <button type='button' onClick={onTurnOnCamera} data-qa='turn-on-camera'>
                    {t('Turn my camera ON')}
                </button>
            </>

        )
    }

    return (
        <>
            <div style={{
                "top": 50,
                "right": 20,
                "fontSize": 24,
                "fontWeight": 600,
                "position": "absolute",
            }}>
                {isRecording && <VideoTimer onStopRecording={onStopRecording} timeLimit={timeLimit}/>}
            </div>
            <div style={{
                "position": "absolute",
                "left": 0,
                "right": 0,
                "top": "84%",
                "display": "flex",
                "alignItems": "center",
                "justifyContent": "center"
            }}>
                <div>{renderContent()}</div>
            </div>
        </>
    )
}
