import * as React from 'react';
import { useCallback, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Link } from "react-router-dom";
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import { useDispatch, useSelector } from "react-redux";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { createUserAction } from "../../store/action-creators/auth-action";

const SignUp = () => {
    const theme = createTheme();
    const dispatch = useDispatch();

    const [ signUpForm, setSignUpForm ] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: ''
    });

    const user = useSelector(state => state.authReducer.userData);

    const handleSubmit = useCallback((event) => {
        event.preventDefault();

        setSignUpForm({
            firstName: '',
            lastName: '',
            email: '',
            password: ''
        });

        dispatch(createUserAction(signUpForm));
    }, [ dispatch, signUpForm ]);

    if (user && user.fields) {
        const userData = {
            userId: user.id,
            name: user.fields.Name,
            email: user.fields.email
        };
        localStorage.setItem("user", JSON.stringify(userData));
    }

    const handlerOnChange = useCallback((event) => {
        setSignUpForm({
            ...signUpForm,
            [event.target.name]: event.target.value
        });
    }, [ signUpForm ]);

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline/>
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon/>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign up
                    </Typography>
                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    autoComplete="given-name"
                                    name="firstName"
                                    required
                                    fullWidth
                                    id="firstName"
                                    label="First Name"
                                    value={signUpForm.firstName}
                                    onChange={handlerOnChange}
                                    autoFocus
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    fullWidth
                                    id="lastName"
                                    label="Last Name"
                                    name="lastName"
                                    value={signUpForm.lastName}
                                    onChange={handlerOnChange}
                                    autoComplete="family-name"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    value={signUpForm.email}
                                    onChange={handlerOnChange}
                                    autoComplete="email"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type="password"
                                    id="password"
                                    value={signUpForm.password}
                                    onChange={handlerOnChange}
                                    autoComplete="new-password"
                                />
                            </Grid>
                        </Grid>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Sign Up
                        </Button>
                        <Grid container justifyContent="flex-end">
                            <Grid item>
                                <Link to='/login'>
                                    Already have an account? Sign in
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
}

export default SignUp;