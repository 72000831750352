import { put } from 'redux-saga/effects';
import { QuestionsApi } from "../../services/api/questionsApi";
import { questionsTypes } from "../action-types/questions-types";

export function* getQuestionsSaga() {
    try {
        yield put({ type: questionsTypes.GET_QUESTIONS_REQUEST });

        const response = yield QuestionsApi.getQuestions();

        if (response.status === 200) {
            yield put({ type: questionsTypes.GET_QUESTIONS_SUCCESS, payload: response.data.records });
        } else {
            yield put({ type: questionsTypes.GET_QUESTIONS_ERROR, payload: response.error.message });
        }
    } catch (e) {
        yield put({ type: questionsTypes.GET_QUESTIONS_ERROR, payload: e.message });
    }
}

export function* getQuestionByIdSaga(action) {
    try {
        yield put({ type: questionsTypes.GET_QUESTIONS_BY_ID_REQUEST });

        const { data, error, status } = yield QuestionsApi.getQuestionsById(action.payload);

        if (status === 200) {
            yield put({ type: questionsTypes.GET_QUESTIONS_BY_ID_SUCCESS, payload: data.fields });
        } else {
            yield put({ type: questionsTypes.GET_QUESTIONS_BY_ID_ERROR, payload: error.message });
        }
    } catch (e) {
        yield put({ type: questionsTypes.GET_QUESTIONS_BY_ID_ERROR, payload: e.message });
    }
}

export function* getQuestionByUserIdSaga(action) {
    try {
        yield put({ type: questionsTypes.GET_QUESTIONS_BY_UID_REQUEST });
        const response = yield QuestionsApi.getQuestionsByUserId(action.payload);

        if (response.status === 200) {
            yield put({ type: questionsTypes.GET_QUESTIONS_BY_UID_SUCCESS, payload: response.data.records });
        } else {
            yield put({ type: questionsTypes.GET_QUESTIONS_BY_UID_ERROR, payload: response.error.message });
        }
    } catch (e) {
        yield put({ type: questionsTypes.GET_QUESTIONS_BY_UID_ERROR, payload: e.message });
    }
}

export function* createQuestionSaga(action) {
    try {
        yield QuestionsApi.createQuestion(action.payload);
    } catch (e) {
        yield put({ type: questionsTypes.GET_QUESTIONS_ERROR, payload: e.message });
    }
}

export function* clearQuestionsStore() {
    try {
        yield put({ type: questionsTypes.CLEAR_QUESTIONS_STORE_REQUEST });
        yield put({ type: questionsTypes.CLEAR_QUESTIONS_STORE_SUCCESS });
    } catch (e) {
        yield put({ type: questionsTypes.GET_QUESTIONS_ERROR, payload: e.message });
    }
}