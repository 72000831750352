import { put } from 'redux-saga/effects'
import { AuthApi } from "../../services/api/authApi";
import { loginTypes, usersRegistrationTypes } from "../action-types/auth-types";

export function* registrationSaga(action) {
    try {
        yield put({ type: usersRegistrationTypes.REGISTRATION_REQUEST });
        const response = yield AuthApi.createUser(action.payload);
        if (response.data) {
            yield put({ type: usersRegistrationTypes.REGISTRATION_SUCCESS, payload: response.data });
        } else {
            yield put({ type: usersRegistrationTypes.REGISTRATION_ERROR, payload: response.error.message });
        }
    } catch (e) {
        yield put({ type: usersRegistrationTypes.REGISTRATION_ERROR, payload: e.message });
    }
}

export function* loginSaga(action) {
    try {
        yield put({ type: loginTypes.LOGIN_REQUEST });
        const user = yield AuthApi.getUserByFilter(action.payload);
        if (!user) {
            yield put({ type: loginTypes.LOGIN_ERROR, payload: { message: "user not found" } });
            return;
        }
        const userData = {
            email: user.fields.email,
            userId: user.id,
            name: user.fields.Name
        };

        localStorage.setItem('user', JSON.stringify(userData));
        yield put({ type: loginTypes.LOGIN_SUCCESS, payload: user });

    } catch (e) {
        yield put({ type: loginTypes.LOGIN_ERROR, payload: e.message });
    }
}