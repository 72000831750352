import { put } from "redux-saga/effects";
import { filterByLockedVideo, sortByCreatedTime } from "../../services/helpers/sorts";
import { AnswersApi } from "../../services/api/answersApi";
import { tagsTypes } from "../action-types/tags-types.js";
import { answersTypes } from "../action-types/answers-types";
import { uploadVideo } from '../../services/helpers/createChunksHelper';
import { getAnswersByQuestionsIdHelper } from '../../services/helpers/getVideosByQuestion.js'

export function* getAllAnswers() {
    try {
        yield put({type: answersTypes.GET_ANSWERS_REQUEST});
        const response = yield AnswersApi.getAllAnswers();
        const sortedAnswers = sortByCreatedTime(response.data.records);
        const notClosedVideos = sortedAnswers.filter(answer => !answer.fields.hasOwnProperty('isLockedVideo'));

        if (response.status === 200) {
            yield put({type: answersTypes.GET_ANSWERS_SUCCESS, payload: notClosedVideos});
        } else {
            yield put({type: answersTypes.GET_ANSWERS_ERROR, payload: response.error.message});
        }
    } catch (e) {
        yield put({type: answersTypes.GET_ANSWERS_ERROR, payload: e.message});
    }
}

export function* createAnswerSaga(action) {
    try {
        const {tags} = action.payload;

        yield put({type: answersTypes.CREATE_ANSWERS_REQUEST});

        const response = yield AnswersApi.createAnswer(action.payload);

        const answerId = response.data.id;

        yield put({
            type: tagsTypes.CREATE_TAG, payload: {
                tags,
                answerId
            }
        });

        if (response.status === 200) {
            yield put({type: answersTypes.CREATE_ANSWERS_SUCCESS, payload: response});
        } else {
            yield put({type: answersTypes.CREATE_ANSWERS_ERROR, payload: response.error.message});
        }
    } catch (e) {
        yield put({type: answersTypes.CREATE_ANSWERS_ERROR, payload: e.message});
    }
}

export function* uploadVideoSaga(action) {
    try {
        yield put({type: answersTypes.UPLOAD_ANSWER_VIDEO_REQUEST})
        const {file, questionId, tags} = action.payload;

        const fd = new FormData();
        fd.append("video", file);

        const data = yield uploadVideo(file);

        yield put({
            type: answersTypes.CREATE_ANSWERS, payload: {
                url: data.assets.mp4,
                thumbnail: data.assets.thumbnail,
                question: questionId ? questionId : null,
                videoId: data.videoId,
                tags
            }
        });

        yield put({type: answersTypes.UPLOAD_ANSWER_VIDEO_SUCCESS});

    } catch (e) {
        yield  put({type: answersTypes.UPLOAD_ANSWER_VIDEO_ERROR});
    }
}

export function* getAnswersByUid(action) {
    try {
        yield put({type: answersTypes.GET_ANSWERS_BY_UID_REQUEST});
        const response = yield AnswersApi.getAnswersByUid(action.payload);

        const sortedData = sortByCreatedTime(response.data.records);

        if (response.status === 200) {
            yield put({
                type: answersTypes.GET_ANSWERS_BY_UID_SUCCESS,
                payload: filterByLockedVideo(sortedData, action.payload)
            });
        } else {
            yield put({type: answersTypes.GET_ANSWERS_BY_UID_ERROR, payload: response.error.message});
        }
    } catch (e) {
        yield put({type: answersTypes.GET_ANSWERS_BY_UID_ERROR, payload: e.message});
    }
}

export function* changeVideoStatus(action) {
    try {
        yield put({type: answersTypes.CHANGE_VIDEO_STATUS_REQUEST});
        const response = yield AnswersApi.changeVideoStatus(action.payload);

        if (response.status === 200) {
            yield put({
                type: answersTypes.CHANGE_VIDEO_STATUS_SUCCESS,
                payload: response.data.records
            })
        } else {
            yield put({
                type: answersTypes.CHANGE_VIDEO_STATUS_ERROR,
                payload: response.error.message
            })
        }
    } catch (e) {
        yield put({type: answersTypes.CHANGE_VIDEO_STATUS_ERROR, payload: e.message});
    }
}

export function* getAnswersByQuestionsId(action) {
    try {
        yield put({type: answersTypes.GET_ANSWERS_BY_QUESTION_ID_REQUEST});
        const response = yield AnswersApi.getAnswersByQuestionsIds(action.payload);
        const data = getAnswersByQuestionsIdHelper(response.data.records, action.payload);

        if (response.status === 200) {
            const notClosedVideos = data.filter(answer => !answer.fields.hasOwnProperty('isLockedVideo'));
            
            yield put({type: answersTypes.GET_ANSWERS_BY_QUESTION_ID_SUCCESS, payload: notClosedVideos});
        } else {
            yield put({type: answersTypes.GET_ANSWERS_BY_QUESTION_ID_ERROR, payload: response.error.message});
        }
    } catch (e) {
        yield put({type: answersTypes.GET_ANSWERS_BY_QUESTION_ID_ERROR, payload: e.message});
    }
}

export function* clearAnswersStoreSaga() {
    try {
        yield put({type: answersTypes.CLEAR_ANSWERS_STORE_REQUEST});
        yield put({type: answersTypes.CLEAR_ANSWERS_STORE_SUCCESS});
    } catch (e) {
        yield put({type: answersTypes.CLEAR_ANSWERS_STORE_ERROR, payload: e.message});
    }
}
