import MyAnswers from "./MyAnswers";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import MyQuestions from "./MyQuestions";
import { useNavigate, useParams } from "react-router";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import LoadingMask from "../../components/Loading/LoadingMask";
import { deepOrange } from "@mui/material/colors";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { getUserById } from "../../store/action-creators/users-actions.js";
import { getQuestionByUid } from "../../store/action-creators/questions-actions";
import { clearAnswersStore, getAnswersByUid, setPlayerAnswers } from "../../store/action-creators/answers-actions";
import "./Profile.css";

const UserPage = () => {
    const [ activeTab, setActiveTab ] = useState(1);
    const {user} = useSelector(state => state.usersReducer);

    const {Name: name, email} = user;

    const {id: userId} = useParams();

    const {answer: answers, isLoading} = useSelector(state => state.answersReducer);
    const {records: questions} = useSelector(state => state.questionReducer);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const getAnswers = useCallback(() => {
        setActiveTab(1);
    }, []);

    const getQuestions = useCallback(() => {
        setActiveTab(2);
    }, []);

    const handleOpenVideo = useCallback((selectedAnswerId) => () => {
        dispatch(setPlayerAnswers({answers, selectedAnswerId}));
        navigate(`/answers-player/${ name }`);
    }, [ answers, dispatch, navigate, name ]);

    useEffect(() => {
        dispatch(getUserById(userId));
        dispatch(getAnswersByUid(userId));
        dispatch(getQuestionByUid(userId));

        return () => dispatch(clearAnswersStore());
    }, [ dispatch, userId ]);

    return (
        <Box className="profile-card" style={ {
            paddingTop: '50px'
        } }>
            <Box className="avatar__box">
                <Avatar className="avatar" sx={ {bgcolor: deepOrange[500]} }>{ name }</Avatar>
            </Box>
            <Box>
                <Typography style={ {"textAlign": "center"} }>
                    { name }
                </Typography>
            </Box>
            <Box className="followers-box">
                <Box className="followers-tab">
                    <b>120</b>
                    <span className="">following</span>
                </Box>
                <Box className="followers-tab">
                    <b>160</b>
                    <span className="">followers</span>
                </Box>
                <Box className="followers-tab">
                    <b>80</b>
                    <span className="">likes</span>
                </Box>
            </Box>
            <Box className="ask__button-box">
                <Button style={ {backgroundColor: '#7165E3'} } variant="contained">Ask a question</Button>
            </Box>
            <Box className="tabs-box">
                <Box onClick={ getAnswers } className={ `action-tab ${ activeTab === 1 ? "active" : "" }` }>
                    Answers ({ answers ? answers.length : 0 })
                </Box>
                <Box onClick={ getQuestions } className={ `action-tab ${ activeTab === 2 ? "active" : "" }` }>
                    Questions ({ questions ? questions.length : 0 })
                </Box>
            </Box>
            <Box className="answers-questions_box">
                <Box sx={ {flexGrow: 1} } style={ {padding: "10px"} }>
                    <Grid container spacing={ {xs: 2, md: 3} } columns={ {xs: 4, sm: 8, md: 12} }>
                        {
                            activeTab === 1
                                ?
                                answers && answers.length
                                    ?
                                    answers.map(({fields, id}, index) => <MyAnswers
                                        fields={ fields }
                                        setOpened={ handleOpenVideo(id) } key={ index }/>)
                                    :
                                    <LoadingMask isLoading={ isLoading }/>
                                :
                                null
                        }

                        {
                            activeTab === 2
                                ?
                                questions && questions.length
                                    ?
                                    questions.map((question, index) => <MyQuestions email={ email }
                                                                                    question={ question }
                                                                                    key={ index }/>)
                                    :
                                    <span>No questions</span>
                                :
                                null
                        }
                    </Grid>
                </Box>
            </Box>
        </Box>
    );
};

export default UserPage;
