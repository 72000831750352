import axios from "axios";
import { BASE_URL } from "./urls";

export class AnswersApi {
    static async createAnswer({url, thumbnail, question, videoId}) {

        const {userId} = JSON.parse(localStorage.getItem('user'));

        const fields = {
            "thumbnail": thumbnail,
            "answer": new Date().valueOf().toString(),
            "videoAnswer": url,
            "responder": [ userId ],
            "question": question ? [ question ] : null,
            videoId,
            "videoForQuestions": question ? [ question ] : null,
            "responderName": [ userId ],
            "thumbnailForVideo": [ question ],
            'isLockedVideo': false
        }

        const config = {
            headers: {
                'Authorization': `Bearer ${ process.env.REACT_APP_API_KEY }`
            }
        };

        return axios.post(`${ BASE_URL }${ process.env.REACT_APP_BASE_ID }/answers`, {fields}, config);
    };

    static async changeVideoStatus({status, answerId}) {
        const records = [
            {
                id: answerId,
                fields: {
                    'isLockedVideo': status
                }
            }
        ];

        const config = {
            headers: {
                'Authorization': `Bearer ${ process.env.REACT_APP_API_KEY }`
            }
        };

        return axios.patch(`${ BASE_URL }${ process.env.REACT_APP_BASE_ID }/answers`, {records}, config);
    };

    static async getAnswersByUid(userId) {

        return axios.get(`${ BASE_URL }${ process.env.REACT_APP_BASE_ID }/answers`, {
            headers: {
                'Authorization': `Bearer ${ process.env.REACT_APP_API_KEY }`
            },
            params: {
                filterByFormula: `{userIdResponder} = "${ userId }"`
            }
        })
    };

    static async getAllAnswers() {
        return axios.get(`${ BASE_URL }${ process.env.REACT_APP_BASE_ID }/answers`, {
            headers: {
                'Authorization': `Bearer ${ process.env.REACT_APP_API_KEY }`
            }
        })
    };

    static async getAnswersByIds() {
        return axios.get(`${ BASE_URL }${ process.env.REACT_APP_BASE_ID }/answers`, {
            headers: {
                'Authorization': `Bearer ${ process.env.REACT_APP_API_KEY }`
            }
        })
    }

    static async getAnswersByQuestionsIds() {
        return axios.get(`${ BASE_URL }${ process.env.REACT_APP_BASE_ID }/answers`, {
            headers: {
                'Authorization': `Bearer ${ process.env.REACT_APP_API_KEY }`
            }
        })
    }
}
