import axios from "axios";
import { BASE_URL } from "./urls";

export class AuthApi {
    static async createUser({ firstName, lastName, email, password }) {
        const userData = {
            fields: {
                "Name": firstName,
                "Last name": lastName,
                "password": password,
                "email": email
            }
        }

        return axios.post(`${BASE_URL}${process.env.REACT_APP_BASE_ID}/users`, userData, {
            headers: {
                'Authorization': `Bearer ${process.env.REACT_APP_API_KEY}`
            }
        })
    };

    static async getUserByFilter({ email, password }) {
        try {
            const { data } = await axios.get(`${BASE_URL}${process.env.REACT_APP_BASE_ID}/users`, {
                headers: {
                    'Authorization': `Bearer ${process.env.REACT_APP_API_KEY}`,
                },
                params: {
                    filterByFormula: `AND({email} = "${email}", {password} = "${password}")`
                }
            })

            if (data.records && data.records.length) {
                return data.records[0]
            }

        } catch (e) {
            console.log(e);
        }
    }
}