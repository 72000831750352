import { userTypes } from "../../action-types/users-types.js";

const initialState = {
    user: {},
    isError: null,
    isLoading: false
};

export const usersReducer = (state = initialState, action) => {
    switch (action.type) {
        case userTypes.GET_USER_BY_ID_REQUEST:
            return { ...state, isLoading: true }
        case userTypes.GET_USER_BY_ID_SUCCESS:
            return { ...state, isLoading: false, isError: null, user: { ...action.payload } }
        case userTypes.GET_USER_BY_ID_ERROR:
            return { ...state, isLoading: false, isError: action.payload }

        default:
            return state
    }
};