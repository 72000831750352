import * as React from 'react';
import { useCallback, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Alert, AlertTitle } from "@mui/material";
import CssBaseline from '@mui/material/CssBaseline';
import { useDispatch, useSelector } from "react-redux";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { loginUserAction } from "../../store/action-creators/auth-action";


const Login = () => {
    const [ signInForm, setSignInForm ] = useState({
        email: '',
        password: ''
    });

    const theme = createTheme();
    const dispatch = useDispatch();

    const { isError } = useSelector(state => state.authReducer);

    const handleSubmit = useCallback((event) => {
        event.preventDefault();
        dispatch(loginUserAction(signInForm));
    }, [ signInForm, dispatch ]);

    const handlerOnChange = useCallback((event) => {
        setSignInForm({
            ...signInForm,
            [event.target.name]: event.target.value
        });
    }, [ signInForm ]);

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline/>
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon/>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
                    {
                        isError
                            ?
                            <Alert severity="error" sx={{ width: '100%', flexAlign: 'center' }}>
                                <AlertTitle>Error</AlertTitle>
                                <strong>{isError.message}</strong>
                            </Alert>
                            : null
                    }
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            value={signInForm.email}
                            onChange={handlerOnChange}
                            autoComplete="email"
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            value={signInForm.password}
                            onChange={handlerOnChange}
                            autoComplete="current-password"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Sign In
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link href="#" variant="body2">
                                    Forgot password?
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link href="#" variant="body2">
                                    {"Don't have an account? Sign Up"}
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
};

export default Login;